<template>
  <b-card class="w-100 header-card mt-2">
    <slot></slot>
    <span class="card-title">{{title}}</span>
  </b-card>
</template>

<script>
export default {
  props: ["title"]
};
</script>

<style scoped>
.header-card {
  height: 130px !important;
  flex-direction: row;
  align-items: flex-end;
}

.card-title {
  color: gray;
}
</style>