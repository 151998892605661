<template>
  <div>
    <b-form @submit.prevent="salvarCaixa" novalidate>
      <h3>Dados do Caixa</h3>
      <hr />
      <b-form-group label="Nome do Caixa:">
        <b-form-input
          placeholder="e.g Caixa 1"
          maxlength="150"
          v-model="dadosCaixa.nome"
          :state="dadosCaixa.nome ? true : false"
        />
      </b-form-group>

      <b-form-group label="Valor Inicial:" label-cols="2">
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text">R$</span>
          </b-input-group-prepend>
          <b-form-input
            placeholder="0,00"
            type="number"
            min="0"
            value="0.00"
            v-model="dadosCaixa.valor"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox v-model="dadosCaixa.mostrarNaDashboard">
          Mostrar na Dashboard
        </b-form-checkbox>
      </b-form-group>
      
      <!-- <b-form-group>
        <b-form-checkbox v-model="dadosCaixa.investimento">
          Investimento
        </b-form-checkbox>
      </b-form-group> -->

      <b-button class="float-right" type="submit">Salvar Caixa</b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    dadosCaixa: {
      nome: "",
      valor: (0).toFixed(2),
      mostrarNaDashboard: true,
      investimento: false,
      perfil: "",
    },
  }),
  methods: {
    salvarCaixa() {
      let { dadosCaixa } = this;

      dadosCaixa.valor = dadosCaixa.valor || 0;

      if (!dadosCaixa.nome) return;
      dadosCaixa.perfil = this.$store.state.perfil.perfil;
      this.$store.dispatch("caixas/add", dadosCaixa);

      this.dadosCaixa = {
        nome: "",
        valor: 0,
        mostrarNaDashboard: true,
        investimento: false,
        perfil: "",
      };
    },
  },
};
</script>

<style>
</style>