<template>
  <div>
    <dashboard-pessoa-juridica v-if="verificaPerfil('pj')"></dashboard-pessoa-juridica >
    <dashboard-pessoa-fisica v-if="verificaPerfil('pf')"></dashboard-pessoa-fisica >
  </div>
</template>

<script>
import DashboardPessoaFisica from "./DashboardPessoaFisica.vue";
import DashboardPessoaJuridica from "./DashboardPessoaJuridica.vue";
import Developening from "@/views/Developening.vue";

export default {
  components: {
    DashboardPessoaFisica,
    DashboardPessoaJuridica,
    Developening
  },
  methods: {
    verificaPerfil(perfil){
      return this.$store.state.perfil.perfil == perfil
    }
  }
}
</script>

<style>
</style>