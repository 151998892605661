<template>
  <b-modal
    id="modal-agendar"
    title="Agendamento"
    size="lg"
    ok-only
    @ok.prevent="submitAgendamento"
    ref="modalAgendamento"
  >
    <b-form>
      <b-form-group label="Aluno">
        <vue-bootstrap-typeahead
          v-model="aluno"
          :data="alunosAutocompleteItems"
          placeholder="Digite o nome do aluno"
          :serializer="item => item.nome"
          ref="aluno"
          @hit="selectedAluno = $event"
        />
      </b-form-group>
      <b-form-group label="Dia da Semana">
        <b-form-select v-model="dia">
          <option
            :value="''"
            selected
            disabled
          >Selecione o dia da semana</option>
          <option :value="'Segunda'">Segunda-feira</option>
          <option :value="'Terça'">Terça-feira</option>
          <option :value="'Quarta'">Quarta-feira</option>
          <option :value="'Quinta'">Quinta-feira</option>
          <option :value="'Sexta'">Sexta-feira</option>
          <option :value="'Sábado'">Sábado</option>
          <option :value="'Domingo'">Domingo</option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Modalidade">
        <b-form-select v-model="modalidade">
          <option :value="'PRESENCIAL'">Presencial</option>
          <option :value="'REMOTO'">Remoto</option>
        </b-form-select>
      </b-form-group>
      <b-row>
        <b-col md="6">
          <b-form-group label="Hora Inicial:">
            <date-picker
              format="HH:mm"
              valuetype="date"
              placeholder="Selecione a hora inicial"
              v-model="horaInicio"
              ref="horaInicio"
              class="picker-hora-agendamento w-100"
              type="time"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Hora Final">
            <date-picker
              format="HH:mm"
              valuetype="date"
              placeholder="Selecione a hora final"
              v-model="horaFim"
              ref="horaFim"
              class="picker-hora-agendamento w-100"
              type="time"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

import * as AlunoService from "@/services/AlunoService";
import * as AgendamentoService from "@/services/AgendamentoService";

import * as DateUtils from "@/utils/dateUtils";
import AgendaAlunoVue from "../dashboard/dashboardAluno/AgendaAluno.vue";

export default {
  data: () => ({
    id: "",
    horaInicio: "",
    horaFim: "",
    aluno: "",
    dia: "",
    modalidade: "PRESENCIAL",
    selectedAluno: null,
    alunosAutocompleteItems: [],
  }),
  components: {
    DatePicker,
    VueBootstrapTypeahead,
  },
  async mounted() {
    const { data: alunos } = await AlunoService.getAutocompleteItems();
    this.alunosAutocompleteItems = alunos;
  },
  methods: {
    showEdit(data) {
      const { horaInicio, horaFim, diaAgendamento, modalidade } = data;

      this.id = data.idAgendamento;

      this.horaInicio = this.getDateEdit(horaInicio);
      this.horaFim = this.getDateEdit(horaFim);

      this.modalidade = modalidade;

      this.dia = diaAgendamento;
      this.selectedAluno = data;
      this.aluno = data.nome;

      this.$refs.modalAgendamento.show();

      this.$nextTick(() => {
        this.$refs.aluno.inputValue = data.nome;
      });
    },
    getDateEdit(hhmm) {
      const dt = new Date();
      const split = hhmm.split(":");

      dt.setHours(split[0]);
      dt.setMinutes(split[1]);

      return dt;
    },
    submitAgendamento() {
      let { horaInicio, horaFim, selectedAluno, dia, modalidade } = this;

      if (!selectedAluno) {
        this.$swal("Erro", "Selecione o aluno", "error");
      } else if (!dia) {
        this.$swal("Erro", "Selecione o dia da semana", "error");
      } else if (!this.modalidade) {
        this.$swal("Erro", "Selecione a modalidade", "error");
      } else if (!horaFim || !horaFim) {
        this.$swal(
          "Erro",
          "Selecione a hora de inicio e fim do período",
          "error"
        );
      } else if (horaInicio > horaFim) {
        this.$swal(
          "Erro",
          "A Hora Final deve ser posterior à Hora Inicial",
          "error"
        );
      } else {
        horaInicio = horaInicio.toLocaleTimeString("pt-BR");
        horaFim = horaFim.toLocaleTimeString("pt-BR");

        horaInicio = DateUtils.removeTimeSeconds(horaInicio);
        horaFim = DateUtils.removeTimeSeconds(horaFim);

        // Salva o agendamento
        const agendamento = {
          aluno: selectedAluno._id,
          horaInicio,
          horaFim,
          dia,
          modalidade
        };

        const request = this.id
          ? AgendamentoService.update(this.id, agendamento)
          : AgendamentoService.add(agendamento);

        request
          .then((r) => {
            this.$swal("Sucesso", "O Agendamento foi salvo", "success").then(
              () => {
                this.clear();
              }
            );

            this.$refs["modalAgendamento"].hide();

            this.$emit("newAgendamento");
          })
          .catch(() =>
            this.$swal(
              "Erro",
              "Ocorreu um erro, fale com o administrador",
              "error"
            )
          );
      }
    },
    clear() {
      this.horaInicio = "";
      this.horaFim = "";
      this.aluno = "";
      this.selectedAluno = null;
      this.dia = "";
    },
  },
  watch: {
    horaInicio() {
      let hora = new Date(this.horaInicio);
      hora.setHours(hora.getHours() + 1);

      this.horaFim = hora;
    },
  },
};
</script>

<style>
.mx-datepicker-main {
  z-index: 999999 !important;
}
</style>