<template>
  <div>
    <div class="table-responsive">
      <table class="table mt-3 table-bordered table-despesas">
        <thead>
          <tr>
            <th>Dia</th>
            <th>Nome</th>
            <th>Valor</th>
            <th>Pagar</th>
            <th class='text-center'>*</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="despesa in despesas"
            :key="despesa._id"
          >
            <td class="text-center">{{despesa.dia}}</td>
            <td>{{despesa.nome}}</td>
            <td>R$ {{despesa.valor.toFixed(2)}}</td>
            <td
              v-if="despesa.pago"
              class="text-center text-success"
            >
              Pago
            </td>
            <td
              v-else
              class="text-center"
            >
              <b-btn
                :id="`popover-${despesa._id}`"
                variant="light"
              >
                <font-awesome-icon
                  :icon="['fas', 'check']"
                  class="text-danger btn-apagar"
                />
              </b-btn>

              <b-popover
                :target="`popover-${despesa._id}`"
                placement="bottom"
                title="Pagar"
                triggers="hover focus"
              >
                <div class="d-flex">
                  <b-form-select
                    v-model="despesa.caixa"
                    class="mr-2"
                  >
                    <option
                      :value="null"
                      disabled
                    >Selecione o Caixa</option>
                    <option
                      v-for="caixa in caixas.caixas.filter(caixa => caixa.perfil == perfil)"
                      :value="caixa.id"
                      :key="caixa.id"
                    >{{caixa.nome}}</option>
                  </b-form-select>

                  <b-btn
                    variant="light"
                    @click="pagar(despesa)"
                  >
                    Ok
                  </b-btn>
                </div>
              </b-popover>

            </td>
            <td class="text-center">
              <b-button
                variant="danger"
                @click="deleteDespesa(despesa._id)"
                :disabled="deleting.includes(despesa._id)"
                size="sm"
              >
                <font-awesome-icon
                  :icon="['fas', 'spinner']"
                  spin
                  v-if="deleting.includes(despesa._id)"
                />
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  v-else
                />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-alert
      variant="dark"
      :show="despesas.length == 0"
    >
      Nada encontrado
    </b-alert>
  </div>
</template>

<script>
import * as DespesaService from "@/services/DespesaService";
import { mapState } from "vuex";

export default {
  props: ["despesas"],
  data: () => ({
    deleting: [],
    perfil: this.$store.state.perfil.perfil
  }),
  computed: {
    ...mapState(["caixas"]),
  },
  methods: {
    pagar(despesa) {
      DespesaService.pay(despesa._id, despesa.caixa).then((res) => {
        this.$emit("pay", res.data);
      });
    },
    deleteDespesa(id) {
      this.deleting.push(id);

      DespesaService.remove(id)
        .then(() => {
          this.$emit("onDelete");
        })
        .catch(() => {
          this.$swal(
            "Erro",
            "Ocorreu um erro ao apagar a despesa, fale com o administrador do sistema",
            "error"
          );
        })
        .finally(() => {
          // Remove da array q está deletando
          this.deleting.filter((d) => d != id);
        });
    },
  },
};
</script>

<style>
.table-despesas tr td {
  vertical-align: middle !important;
}
</style>