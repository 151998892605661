<template>
  <div>

    <b-container
      class="mb-5 container-alunos"
      fluid
    >
      <b-row class="mt-3">
        <b-col md="12">
          <b-row>
            <b-col
              md="5"
              class="mb-2"
            >
              <div>
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">
                      <font-awesome-icon
                        :icon="['fas', 'search']"
                        class="icon"
                      />
                    </span>
                  </b-input-group-prepend>

                  <b-form-input
                    placeholder="Digite o Nome ou o CPF do Aluno"
                    v-model="searchField"
                  />
                </b-input-group>
              </div>
            </b-col>
          </b-row>

          <tabela-alunos
            class="mt-3"
            :searchField="searchField"
            @alunoSelecionado="selecionarAluno"
          />

        </b-col>
      </b-row>
    </b-container>

    <modal-pagamento :aluno="alunoSelecionado" />

    <float-button
      @click="gotoNewAluno"
      label="Novo Aluno"
    />
  </div>

</template>

<script>
import TabelaAlunos from "@/components/alunos/TabelaAlunos";
import ModalPagamento from "@/components/alunos/ModalPagamento";
import FloatButton from "@/components/FloatButton";
import Navbar from "@/components/Navbar";

export default {
  data: () => ({
    searchField: "",
    alunoSelecionado: null
  }),
  components: {
    FloatButton,
    TabelaAlunos,
    Navbar,
    ModalPagamento
  },
  mounted() {
    this.$store.dispatch("alunos/fetch");
  },
  methods: {
    gotoNewAluno() {
      this.$router.push("/alunos/cadastro");
    },
    selecionarAluno(aluno) {
      this.alunoSelecionado = aluno;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>