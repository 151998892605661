<template>
  <table class="table table-bordered mt-4">
    <thead>
      <tr>
        <th>Dia</th>
        <th>Nome</th>
        <th>Valor</th>
        <th
          width="150"
          class="text-center"
        >*</th>
      </tr>
    </thead>
    <tr
      v-for="despesa in despesas"
      :key="despesa._id"
    >
      <td>{{despesa.dia}}</td>
      <td>{{despesa.nome}}</td>
      <td>R$ {{despesa.valorPadrao.toFixed(2)}}</td>
      <td class="text-center">
        <b-button
          variant="danger"
          @click="apagarDespesa(despesa._id)"
        >
          <font-awesome-icon :icon="['fas', 'trash']" />
        </b-button>

        <b-button
          variant="info"
          class="ml-2"
          @click="$emit('editDespesa', despesa)"
        >
          <font-awesome-icon :icon="['fas', 'pen']" />
        </b-button>
      </td>
    </tr>
  </table>
</template>

<script>
import * as DespesaService from "@/services/DespesaService";

export default {
  props: ["despesas"],
  methods: {
    apagarDespesa(id) {
      DespesaService.deleteFixa(id).then(() => {
        this.$emit("onDeleteDespesa");
      });
    },
  },
};
</script>

<style>
</style>