<template>
  <b-modal
    id="modal-atrasos"
    :title="`Pagamentos - ${aluno ? aluno.nome : ''}`"
    size="lg"
    ok-only
    ref="modalAtrasos"
  >
    <table class="table table-striped">
      <thead v-if="atrasos.length > 0">
        <tr>
          <th class="text-danger text-center" colspan="3">Pendências</th>
        </tr>
        <tr>
          <th class="text-danger" colspan="1">Mês</th>
          <th class="text-danger" colspan="1">Valor</th>
          <th class="text-success" colspan="1"></th>
        </tr>
      </thead>
      <tbody v-if="atrasos.length > 0">
        <tr v-for="(atraso, i) in atrasos" :key="i">
          <td>{{ atraso.mes }}</td>
          <td>{{ atraso.valor | currency }}</td>
          <td></td>
        </tr>
      </tbody>
      <thead v-if="recebidos.length > 0">
        <tr>
          <th class="text-success text-center" colspan="3">
            Pagamentos Efetuados
          </th>
        </tr>
        <tr>
          <th class="text-success" colspan="1">Mês</th>
          <th class="text-success" colspan="1">Valor</th>
          <th class="text-success text-right" colspan="1">Excluir</th>
        </tr>
      </thead>
      <tbody v-if="recebidos.length > 0">
        <tr v-for="(recebido, i) in recebidos" :key="i">
          <td class="text-success">{{ recebido.mesReferencia }}</td>
          <td class="text-success">{{ recebido.valor | currency }}</td>
          <td class="text-right">
            <b-btn
              variant="primary-dark"
              @click="deletePagamento(recebido._id)"
            >
              <font-awesome-icon :icon="['fas', 'trash-alt']" />
            </b-btn>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table table-striped" v-if="observacoes.length">
      <thead>
        <tr>
          <th class="text-info text-center" colspan="2">Observações</th>
        </tr>
        <tr>
          <th class="text-info">Observação</th>
          <th class="text-info text-right">Excluir</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(observacao, i) in observacoes" :key="i">
          <td class="text-info">{{ observacao.texto }}</td>
          <td class="text-right">
            <b-btn
              variant="primary-dark"
              @click="deleteObservacao(observacao._id)"
            >
              <font-awesome-icon :icon="['fas', 'trash-alt']" />
            </b-btn>
          </td>
        </tr>
      </tbody>
    </table>

    <p
      v-if="aluno && aluno.pago && diferencaPagamento != 0"
      :class="{
        'text-info': diferencaPagamento > 0,
        'text-danger': diferencaPagamento < 0,
        'font-weight-bold': true,
      }"
    >
      Diferença de Pagamento: R$ {{ diferencaPagamento | currency }} ({{
        textDiferenca
      }})
    </p>
  </b-modal>
</template>

<script>
import * as PagamentoService from "@/services/PagamentoService";
import * as ObservacaoService from "@/services/ObservacaoService";
export default {
  props: ["aluno"],
  computed: {
    atrasos() {
      if (this.aluno) return this.aluno.pendencias.detalhes;
      else return [];
    },
    recebidos() {
      if (this.aluno) return this.aluno.recebidoMes;
      else return [];
    },
    observacoes() {
      if (this.aluno) return this.aluno.observacoesMes;
      else return [];
    },
    diferencaPagamento() {
      if (this.aluno) return this.aluno.pagamentoMes - this.aluno.valor;
    },
    textDiferenca() {
      return this.diferencaPagamento > 0 ? "pago a mais" : "pago a menos";
    },
  },
  methods: {
    deletePagamento(id) {
      this.$swal({
        title: "Remover Pagamento",
        text: `Deseja realmente remover este pagamento?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
      }).then(({ value: remover }) => {
        if (remover) {
          PagamentoService.remove(id).then(() => {
            this.$emit("toggleRefresh", this.aluno);
          });
        }
      });
    },

    deleteObservacao(id) {
      this.$swal({
        title: "Remover Observação",
        text: `Deseja realmente remover esta observação?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
      }).then(({ value: remover }) => {
        if (remover) {
          ObservacaoService.remove(id).then(() => {
            this.$emit("toggleRefresh", this.aluno);
          });
        }
      });
    },
  },
};
</script>

<style>
</style>