<template>
  <b-row>
    <b-col md="3" class="mb-2">
      <b-card title="Nível" :sub-title="infoAluno.nivel" />
    </b-col>

    <b-col md="3" class="mb-2">
      <b-card title="Data de Inicio" :sub-title="new Date(infoAluno.dtInicio).toLocaleDateString('pt-BR')" />
    </b-col>

    <b-col md="3" class="mb-2">
      <b-card title="Valor" :sub-title="`R$ ${infoAluno.valor.toFixed(2)}`" />
    </b-col>

    <b-col md="3" class="mb-2">
      <b-card title="Conta Depósito" :sub-title="'-'" />
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ['infoAluno']
};
</script>

<style>
</style>