<template>
  <div>

    <b-container class="mb-5 mt-4" fluid>
      <b-row>

        <b-col md="6">
          <form-caixa></form-caixa>
        </b-col>

        <b-col md="6">
          <table-caixas></table-caixas>
        </b-col>

      </b-row>
    </b-container>

  </div>
</template>

<script>
import FloatButton from "@/components/FloatButton";
import Navbar from "@/components/Navbar";

import FormCaixa from '@/components/caixas/FormCaixa'
import TableCaixas from '@/components/caixas/TableCaixas'

export default {
  data: () => ({
    
  }),
  components: {
    FloatButton,
    Navbar,
    FormCaixa,
    TableCaixas
  }
};
</script>