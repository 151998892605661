<template>
  <div class="p-3">
    <tabela-agendamento
      ref="tabelaAgendamentos"
      @edit="showEdit"
    />
    <FloatButton
      label="Novo Agendamento"
      v-b-modal.modal-agendar
    />
    <modal-agendamento
      ref="modalAgendamento"
      @newAgendamento="reloadTable"
    />
  </div>
</template>

<script>
import TabelaAgendamento from "@/components/agendamento/TabelaAgendamento";
import FloatButton from "@/components/FloatButton";
import ModalAgendamento from "@/components/agendamento/ModalAgendamento";

export default {
  components: {
    TabelaAgendamento,
    FloatButton,
    ModalAgendamento,
  },
  methods: {
    reloadTable() {
      console.log(this.$refs);
      this.$refs.tabelaAgendamentos.fetch();
    },
    showEdit(data) {
      this.$refs.modalAgendamento.showEdit(data);
    },
  },
};
</script>

<style>
</style>