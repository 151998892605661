<template>
  <div>
    <b-table
      :fields="fields"
      :items="this.caixas.caixas.filter(caixa => caixa.perfil == this.$store.state.perfil.perfil)"
    >

      <template v-slot:cell(valor)="caixa">
        R$ {{parseFloat(caixa.item.valor).toFixed(2).replace('.', ',')}}
      </template>

      <template v-slot:cell(mostrarNaDashboard)="caixa">
        <div class="text-center">
          <input type="checkbox" 
            class="align-middle"
            v-model="caixa.item.mostrarNaDashboard" 
            @click="toggleMostrarNaDashboard(caixa.item)"
          >
        </div>
      </template>
      <!-- <template v-slot:cell(investimento)="caixa">
        <div class="text-center">
          <input type="checkbox" 
            class="align-middle"
            v-model="caixa.item.investimento" 
            @click="toggleInvestimento(caixa.item)"
          >
        </div>
      </template> -->

      <template v-slot:cell(adicionarDinheiro)="caixa">
        <b-btn
          variant="light"
          v-b-tooltip.hover
          title="Adicionar Dinheiro"
          @click="adicionarDinheiro(caixa.item)"
        >
          <font-awesome-icon
            :icon="['fas', 'plus']"
            class="text-danger btn-apagar"
          />
        </b-btn>
      </template>

      <template v-slot:cell(retirarDinheiro)="caixa">
        <b-btn
          variant="light"
          v-b-tooltip.hover
          title="Retirar Dinheiro"
          @click="retirarDinheiro(caixa.item)"
        >
          <font-awesome-icon
            :icon="['fas', 'minus']"
            class="text-danger btn-apagar"
          />
        </b-btn>
      </template>

      <template v-slot:cell(transferirDinheiro)="caixa">
        <b-btn
          variant="light"
          v-b-tooltip.hover
          title="Transferir Dinheiro"
          @click="transferirDinheiro(caixa.item)"
        >
          <font-awesome-icon
            :icon="['fas', 'exchange-alt']"
            class="text-danger btn-apagar"
          />
        </b-btn>
      </template>

      <template v-slot:cell(apagar)="caixa">
        <font-awesome-icon
          :icon="['fas', 'trash']"
          class="text-danger btn-apagar"
          @click="apagarCaixa(caixa.item)"
        />
      </template>

    </b-table>
  </div>
</template>

<script>
import store from "@/store";
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    fields: [
      { label: "Nome do Caixa", key: "nome" },
      { label: "Valor do Caixa", key: "valor" },
      { label: "Mostrar na Dashboard", key: "mostrarNaDashboard" },
      // { label: "Investimento", key: "investimento" },
      { label: "", key: "adicionarDinheiro" },
      { label: "", key: "retirarDinheiro" },
      { label: "", key: "transferirDinheiro" },
      { label: "", key: "apagar" }
    ]
  }),
  computed: {
    ...mapState(["caixas"]),
  },
  methods: {
    ...mapActions("caixas", ["clear", "fetchAll"]),
    getHtml() {
      return (
        '<input id="swal-input-motivo" placeholder="Motivo" class="swal2-input swal2-placeholder">' +
        '<input id="swal-input-valor" placeholder="Valor" type="number" step="0.01 " class="swal2-input swal2-placeholder">'
      );
    },
    getHtmlTransferir() {
      return (
        `<select id="swal-select-caixa" placeholder="Caixa" class="swal2-input swal2-placeholder">${this.getHtmlCaixas()}</select>` +
        `<input id="swal-input-valor" placeholder="Valor" type="number" step="0.01 " class="swal2-input swal2-placeholder">`
      );
    },
    getHtmlCaixas(){
      let caixasOptions = ''
      this.caixas.caixas.forEach(element => {
        if (element.perfil != this.$store.state.perfil.perfil)
          caixasOptions = caixasOptions + `<option value="${element.id}">${element.nome}</option>`
      });
      return caixasOptions;
    },
    getPreConfirm() {
      const fn = () => {
        return new Promise(function(resolve) {
          resolve({
            motivo: document.querySelector("#swal-input-motivo").value,
            valor: document.querySelector("#swal-input-valor").value
          });
        });
      };

      return fn;
    },
    getPreConfirmTransferirDinheiro() {
      const fn = () => {
        return new Promise(function(resolve) {
          resolve({
            caixa: document.querySelector("#swal-select-caixa").value,
            valor: document.querySelector("#swal-input-valor").value
          });
        });
      };

      return fn;
    },
    getOnOpen() {
      const fn = () => {
        document.querySelector("#swal-input-motivo").focus();
      };

      return fn;
    },
    adicionarDinheiro(caixa) {
      this.$swal({
        title: "Adicionar Dinheiro",
        showCancelButton: true,
        confirmButtonText: "Adicionar",
        html: this.getHtml(),
        preConfirm: this.getPreConfirm(),
        onOpen: this.getOnOpen()
      }).then(({ value }) => {
        if (value) {
          this.$store.dispatch("caixas/addMoney", {
            id: caixa.id,
            motivo: value.motivo,
            valor: value.valor
          });
        }
      });
    },
    retirarDinheiro(caixa) {
      this.$swal({
        title: "Retirar Dinheiro",
        showCancelButton: true,
        confirmButtonText: "Retirar",
        html: this.getHtml(),
        preConfirm: this.getPreConfirm(),
        onOpen: this.getOnOpen()
      }).then(({ value }) => {
        if (value) {
          this.$store.dispatch("caixas/removeMoney", {
            id: caixa.id,
            motivo: value.motivo,
            valor: value.valor
          });
        }
      });
    },
    apagarCaixa(caixa) {
      this.$swal({
        title: "Remover Caixa",
        text: `Deseja realmente remover ${caixa.nome}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim"
      }).then(({ value: remover }) => {
        if (remover) {
          this.$store
            .dispatch("caixas/remove", { id: caixa.id })
            .then(() => {})
            .catch(e => {
              this.$swal("Erro", e.response.data, "error");
            });
        }
      });
    },
    toggleMostrarNaDashboard(caixa) {
      this.$store
        .dispatch("caixas/toggleMostrarNaDashboard", {id: caixa.id})
        .then(() => {})
        .catch(e => {
          this.$swal("Erro", e.response.data, "error");
        });
    },
    toggleInvestimento(caixa) {
      this.$store
        .dispatch("caixas/toggleInvestimento", {id: caixa.id})
        .then(() => {})
        .catch(e => {
          this.$swal("Erro", e.response.data, "error");
        });
    },
    transferirDinheiro(caixa){
      this.$swal({
        title: "Transferir Dinheiro",
        showCancelButton: true,
        confirmButtonText: "Transferir",
        html: this.getHtmlTransferir(),
        preConfirm: this.getPreConfirmTransferirDinheiro(),
        onOpen: this.getOnOpen()
      }).then(({ value }) => {
        if (value) {
          this.$store.dispatch("caixas/removeMoney", {
            id: caixa.id,            
            valor: value.valor
          });
          this.$store.dispatch("caixas/addMoney", {
            id: value.caixa,            
            valor: value.valor
          });
        }
      });
    }
  },
  mounted() {
    if (this.caixas.caixas.length > 0) {
      this.clear();
      this.fetchAll();
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-apagar {
  cursor: pointer !important;
}
</style>