<template>
  <div class="justify-content-center align-items-center w-100 d-flex flex-column" style="height: 88vh; color: #cacaca">
    <font-awesome-icon
      :icon="['fas', 'hammer']"
      style="font-size: 90px"
      class="see-pass"
    />
    <br>
    <h5>Estamos em desenvolvimento...</h5>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>