<template>
  <div>
    <h5>
      <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" />
      Pagamentos
    </h5>

    <table class="table table-bordered mt-3">
      <thead>
        <tr class="text-danger">
          <th>Valor</th>
          <th>Data Vencimento</th>
          <th>Boleto</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="pendencia in listBoletos"
          :key="pendencia.data"
        >
          <td class="align-middle">R$ {{pendencia.valor.toFixed(2)}}</td>
          <td class="align-middle">{{new Date(pendencia.data).toLocaleDateString()}}</td>
          <td>
            <b-button
              size="sm"
              variant="danger"
              @click.prevent="gerarBoleto(pendencia)"
              :disabled="loadingBoleto != null"
            >
              <span>
                Ver boleto
              </span>
              <font-awesome-icon
                v-if="loadingBoleto == pendencia.data"
                class="ml-2"
                :icon="['fas', 'spinner']"
                spin
              />
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <a
      href="#"
      v-b-modal.modal-pagamentos-aluno
      v-if="!all"
      class="text-info"
    >Ver todos</a>
  </div>
</template>

<script>
import * as DashboardAlunoService from "@/services/DashboardAlunoService";

export default {
  props: ["all", "pendencias"],
  data: () => ({
    loadingBoleto: null
  }),
  computed: {
    listBoletos() {
      if (this.all) {
        return this.pendencias;
      } else {
        return this.pendencias.slice(0, 4);
      }
    }
  },
  methods: {
    gerarBoleto(pendencia) {
      const date = new Date(pendencia.data);

      let ano = date.getFullYear(),
        mes = date.getMonth();

      mes = mes.toString().padStart(2, "0");

      this.loadingBoleto = pendencia.data;

      DashboardAlunoService.gerarBoleto(ano, mes)
        .then(res => {
          const url = res.data.url;

          window.open(url);
        })
        .catch(err => {
          this.$swal("Erro", "Ocorre um erro ao gerar o boleto", "error");
        })
        .finally(() => (this.loadingBoleto = null));
    }
  }
};
</script>

<style>
</style>