<template>
  <div class="container">
    <div class="row align-items-center">
      <div class="col">
        <span class="title-value">Renda Média Mensal</span>
        <div class="valor valor-receita">
          <p class="receita">
            R$
            {{ getAverageIncomes() | currency }}
          </p>
        </div>
      </div>
    </div>
    <div class="row align-items-center mt-3">
      <div class="col">
        <span class="title-value">Despesa Média Mensal</span>
        <div class="valor valor-despesa">
          <p class="despesa">
            R$
            {{ getAverageExpenses() | currency }}
          </p>
        </div>
        <div class="info-percent">
          Esse valor corresponde a
          <span class="despesa">{{ getPercentExpenses() }}% </span> sua renda
          média
        </div>
      </div>
    </div>
    <div class="row align-items-center mt-3">
      <div class="col">
        <span class="title-value">Reserva Financeira Total</span>
        <div class="valor valor-reserva">
          <p class="reserva">R$ {{ financialData.investments | currency }}</p>
        </div>
        <div class="info-percent">
          Esse valor corresponde a
          <span class="reserva">{{ getPercentInvestments() }}% </span>da sua
          renda total
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import * as DespesaService from "@/services/DespesaService";
import * as RendaService from "@/services/RendaService";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["caixas"]),
  },
  data() {
    return {
      financialData: {
        incomes: 0,
        expenses: 0,
        totalCalculated: 0,
        investments: 0,
      },
    };
  },
  async created() {
    await this.generateFinancialData();
  },
  methods: {
    getAverageIncomes() {
      return this.financialData.incomes / this.financialData.totalCalculated;
    },
    getAverageExpenses() {
      return this.financialData.expenses / this.financialData.totalCalculated;
    },
    getPercentInvestments() {
      if (this.financialData.investments > 0) {
        const averageIncomes = this.getAverageIncomes();
        const percentage =
          (this.financialData.investments / averageIncomes) * 100;
        return percentage.toFixed(2);
      }
      return 0;
    },
    getPercentExpenses() {
      const averageExpenses = this.getAverageExpenses();
      const averageIncomes = this.getAverageIncomes();
      const percentage = (averageExpenses / averageIncomes) * 100;
      return percentage.toFixed(2);
    },
    async generateFinancialData() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      let totalCalculated = 0;
      for (let month = 1; month <= currentMonth; month++) {
        totalCalculated++;
        const monthString = month.toString().padStart(2, "0");
        await DespesaService.fetch(currentYear, monthString).then((res) => {
          this.financialData.expenses += res.data.total;
        });
        await RendaService.fetch(currentYear, monthString).then((res) => {
          this.financialData.incomes += res.data.total;
        });
      }

      this.financialData.totalCalculated = totalCalculated;
      this.caixas.caixas.forEach((x) => {
        if (x.investimento) {
          this.financialData.investments += x.valor;
        }
      });
    },
  },
};
</script>
  
<style>
.title-value {
  font-weight: bold;
  font-size: 16px;
}

.valor {
  display: flex;
  align-items: center; /* Centraliza verticalmente */
  justify-content: center; /* Centraliza horizontalmente */
  height: 7dvh;
}
.valor-receita {
  border: 1px dotted rgb(74, 248, 74);
  background-color: #d4edda;
}

.receita {
  color: #23c648;
  font-weight: bold;
  font-size: 18px;
}
.valor-despesa {
  border: 1px dotted #f5c6cb;
  background-color: #f8d7da;
}
.despesa {
  color: rgb(247, 3, 3);
  font-weight: bold;
}
.valor-reserva {
  border: 1px dotted #b8daff;
  background-color: #cce5ff;
}
.reserva {
  color: #004085;
  font-weight: bold;
}

.info-percent {
  font-size: 12px;
  color: silver;
  font-weight: bold;
}
</style>