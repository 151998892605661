import store from "@/store";
import api from "./";

export function add(despesa) {
  return api.post("/despesa", despesa);
}

export function editDespesa(id, despesa) {
  return api.put(`/despesa/${id}`, despesa);
}

export function fetch(ano, mes) {
  return api.get(`/despesa/${ano}/${mes}`);
}

export function fetchByDay(ano, mes, filtro) {
  return api.get(`/despesa/day/${ano}/${mes}/${store.state.perfil.perfil}/${filtro}`);
}

export function remove(id) {
  return api.delete(`/despesa/${id}`);
}

export function addFixa(data) {
  return api.post("/despesaFixa", data);
}

export function fetchFixas() {
  return api.get("/despesaFixa");
}

export function editFixa(id, newData) {
  return api.put(`/despesaFixa/${id}`, newData);
}

export function deleteFixa(id) {
  return api.delete(`/despesaFixa/${id}`);
}

export function insertFixas(ano, mes) {
  return api.post(`/despesa/${ano}/${mes}/adicionarFixas`);
}

export function pay(id, caixa) {
  return api.put(`/despesa/${id}/marcarPago`, { caixa });
}
