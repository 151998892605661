<template>
  <b-modal
    id="modal-nova-aba"
    title="Adicionar nova Aba"
    size="lg"
    hide-footer
    ref="modalNovaAba"
  >

    <b-form-group>
      <b-form-input
        placeholder="Nome da Aba"
        v-model="nome"
      />
    </b-form-group>  

    <div class="text-right">
      <b-button
        @click="salvarAba"
        variant="primary-dark"
      >
        Salvar
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import * as ExpressaoSchemaService from "@/services/ExpressaoSchemaService";

export default {
  components: {},
  data: () => ({
    nome: "",
  }),
  methods: {
    salvarAba() {
      const { nome } = this;

      if (!nome) {
        this.$swal("Erro", "Preencha o campo 'Nome'", "error");
      } else {
        var reqPromise;
        reqPromise = ExpressaoSchemaService.add(this.getData());       
      }

        reqPromise
          .then(() => {
            this.$refs.modalNovaAba.hide(); 
            this.$emit("update");
            this.nome = "";          
          })
          .catch(() => {
            this.$swal(
              "Erro",
              "Ocorreu um erro ao criar uma nova aba, fale com o administrador",
              "error"
            );
          });
      },
    getData() {
      return {
        nome: this.nome,
        campos: ["Expression", "What's Mean"]
      };
    }    
  }
};
</script>

<style>
</style>