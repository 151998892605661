<template>
  <div class="shadow p-4 m-3 bg-white mapa-pagamentos-dia">
    <table class="table table-striped">
      <thead>
        <tr>
          <td
            colspan="5"
            class="text-center font-weight-bold color-primary-dark"
          >Dia {{ dia }}</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(lembrete, i) in lembretes"
          :key="i"
          :class="{ 
            'text-success': lembrete.done, 
            done: lembrete.done, 
           }"
        >
          <td style="overflow-wrap: anywhere; min-width: 80px">{{lembrete.nome}}</td>

          <td
            class="text-right"
            v-if="lembrete.done"
          >DONE</td>

          <td
            class="text-center"
            v-else
          >
            <b-btn
              v-b-tooltip.hover title="Marcar como done"
              @click="marcarComoDone(lembrete)"
              variant="light"
            >
              <font-awesome-icon
                :icon="['fas', 'check']"
                class="text-success btn-apagar"
              />
            </b-btn>
          </td>

          <td class="text-center icon-column">
            <b-button
              v-if="!lembrete.done"
              style="color: #676060"
              variant="light"
              @click="editLembrete(lembrete)"
            >
              <font-awesome-icon :icon="['fas', 'pencil-alt']" />
            </b-button>
          </td>

          <td class="text-center">
            <b-button
              variant="light"
              @click="deleteLembrete(lembrete._id)"
              :disabled="deleting.includes(lembrete._id)"
            >
              <font-awesome-icon
                :icon="['fas', 'spinner']"
                spin
                v-if="deleting.includes(lembrete._id)"
              />
              <font-awesome-icon
                :icon="['fas', 'trash']"
                class="text-danger"
                v-else
              />
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>

    <modal-editar-lembrete
      ref="modalLembrete"
      @onsave="updateDate"
    />

  </div>
</template>

<script>
import * as LembreteService from "@/services/LembreteService";
import ModalEditarLembrete from "./ModalEditarLembrete.vue";

export default {
  props: ["dia", "lembretes"],
  components: {
    ModalEditarLembrete,
  },
  data() {
    return {
      deleting: [],
    };
  },
  methods: {
    marcarComoDone(lembrete) {
      LembreteService.marcarComoDone(lembrete._id).then((res) => {
        this.$emit("done", res.data);
      });
    },
    deleteLembrete(id) {
      this.deleting.push(id);

      LembreteService.remove(id)
        .then(() => {
          this.$emit("updateDate");
        })
        .catch(() => {
          this.$swal(
            "Erro",
            "Ocorreu um erro ao apagar o lembrete, fale com o administrador do sistema",
            "error"
          );
        })
        .finally(() => {
          // Remove da array q está deletando
          this.deleting.filter((d) => d != id);
        });
    },
    editLembrete(lembrete) {
      this.$refs.modalLembrete.showEdit(lembrete);
    },
    updateDate() {
      this.$emit("updateDate");
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  color: #555;

  tbody td {
    padding: 8px;
    font-size: 16px !important;
    vertical-align: middle;
  }

  tbody tr td {
    cursor: pointer;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.pago {
  background: #f2fff2 !important;
}

.valor-pago {
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  .mapa-pagamentos-dia {
    flex-grow: 1;
    width: 100%;
    padding: 15px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
</style>