import api from ".";

export function get() {
  return api.get('/sistema')
}

export function add(dados) {
  return api.post("/sistema", dados);
}

export function update(id, dados) {
  return api.put(`/sistema/${id}`, dados);
}

export function remove(id) {
  return api.delete(`/sistema/${id}`);
}
