import api from './'

export function add(data) {
  return api.post('/agendamento/', data)
}

export function update(id, data) {
  return api.put(`/agendamento/${id}`, data)
}

export function remove(id) {
  return api.delete(`/agendamento/${id}`)
}

export function fetchAll() {
  return api.get('/agendamento/')
}
