<template>
  <b-modal
    id="modal-dia-agenda"
    title="Agenda do Dia"
    size="lg"
    ok-only
    :no-close-on-backdrop="true"
    ref="modalDiaAgenda"
  >

    <div class="d-flex">

      <b-input
        placeholder="Digite o evento aqui"
        v-model="descricao"
        @keyup.enter="adicionar"
      />

      <b-btn
        variant="primary-dark"
        class="btn-salvar ml-3"
        @click="adicionar"
      >
        Adicionar
      </b-btn>

    </div>

    <table class="table table-striped mt-4">
      <thead>
        <tr>
          <th
            colspan="2"
            class="text-center color-primary-dark"
          >Eventos do dia {{data | moment('DD/MM/YYYY')}}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="evento in eventos"
          :key="evento._id"
        >
          <td>{{evento.title}}</td>
          <td style="width: 30px">
            <b-button
              variant="danger"
              @click="deleteEvento(evento.id)"
              size="sm"
            >
              <font-awesome-icon :icon="['fas', 'trash']" />
            </b-button>
          </td>
        </tr>

      </tbody>
    </table>

  </b-modal>
</template>

<script>
import * as AgendaService from "@/services/AgendaService";

export default {
  props: ["data", "eventos"],
  data: () => ({
    descricao: null,
  }),
  methods: {
    adicionar() {
      const { data, descricao } = this;

      if (!descricao) return;

      this.$emit("novoEvento", {
        data: this.$moment(data).format("YYYY-MM-DD"),
        descricao,
      });

      this.descricao = "";
    },
    deleteEvento(id) {
      this.$emit("deleteEvento", id);
    },
  },
};
</script>