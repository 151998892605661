import axios from "axios";
import * as CaixaService from "@/services/CaixaService";
import store from "..";

const caixasModule = {
  namespaced: true,

  state: {
    loading: true,
    error: false,
    caixas: []
  },

  mutations: {
    add(state, { _id, nome, valor, mostrarNaDashboard, investimento, perfil }) {
      // Adiciona o caixa ao state
      state.caixas = [
        ...state.caixas,
        {
          id: _id,
          nome,
          valor,
          mostrarNaDashboard,
          investimento,
          perfil
        }
      ];
    },
    updateValue(state, { id, value }) {
      state.caixas.find(x => x.id == id).valor = value;
    },
    remove(state, { id }) {
      // Remove o caixa do state
      state.caixas = state.caixas.filter(c => c.id != id);
    },
    clear(state) {
      state.caixas = [];
    },
    updateMostrarNaDashboard(state, { id, mostrarNaDashboard }) {
      state.caixas.find(x => x.id == id).mostrarNaDashboard = mostrarNaDashboard;
    },
    updateInvestimento(state, { id, investimento }) {
      state.caixas.find(x => x.id == id).investimento = investimento;
    },
  },
  actions: {
    fetchAll({ commit }) {
      // API para pegar todos os caixas
      CaixaService.fetchAll().then(r => {
        const caixas = r.data;
        commit("clear");
        caixas.forEach(caixa => commit("add", caixa));
      });
    },
    add({ commit }, { nome, valor, mostrarNaDashboard, investimento, perfil }) {
      // API para adicionar caixa
      CaixaService.add({ nome, valor, mostrarNaDashboard, investimento, perfil }).then(({ data }) => {
        commit("add", {
          _id: data._id,
          nome,
          valor,
          mostrarNaDashboard,
          investimento,
          perfil
        })
      });
    },
    addMoney({ commit }, { id, valor, motivo }) {
      CaixaService.addMoney(id, valor, motivo).then(({ data }) =>
        commit("updateValue", {
          id: data._id,
          value: data.valor
        })
      );
    },
    removeMoney({ commit }, { id, valor, motivo }) {
      CaixaService.removeMoney(id, valor, motivo).then(({ data }) =>
        commit("updateValue", {
          id: data._id,
          value: data.valor
        })
      );
    },
    remove({ commit }, { id }) {
      // API para Remover caixa
      return CaixaService.remove(id).then(() => {
        commit("remove", { id });
      });
    },
    clear({ commit }) {
      commit("clear");
    },
    toggleMostrarNaDashboard({ commit }, { id }) {
      CaixaService.toggleMostrarNaDashboard(id).then(({ data }) =>
        commit("updateMostrarNaDashboard", {
          id: data._id,
          mostrarNaDashboard: data.mostrarNaDashboard
        })
      );
    },
    toggleInvestimento({ commit }, { id }) {
      CaixaService.toggleInvestimento(id).then(({ data }) =>
        commit("updateInvestimento", {
          id: data._id,
          investimento: data.investimento
        })
      );
    },
  }
};

export default caixasModule;
