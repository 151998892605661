import api from ".";

export function add(renda) {
  return api.post("/renda", renda);
}

export function fetch(ano, mes) {
  return api.get(`/renda/${ano}/${mes}`);
}

export function remove(id) {
  return api.delete(`/renda/${id}`);
}

export function alteraValorRestante(renda) {
  return api.put(`/renda/${renda._id}/alteraValorRestante`, renda);
}