<template>
    <div v-if="dadosPf && dadosPj">
      <header-pf :dadosPj="dadosPj" :dadosPf="dadosPf"/>
  
      <alertas :alertas="dadosPf.despesasComVencimentoProximo" tipo="Despesa" class="mt-4 mb-4"/>
    
    </div>
  </template>
  
  <script>
  import * as DashboardService from "@/services/DashboardService";
  
  import Alertas from "./dashboardAdmin/Alertas";
  import HeaderPf from "./dashboardAdmin/HeaderPf.vue";
  
  export default {
    data() {
      return {
        dadosPj: null,
        dadosPf: null,
      };
    },
    components: {
      Alertas,
      HeaderPf,
    },
    mounted() {
      DashboardService.fetchDashboard('pj').then(res => {
        this.dadosPj = res.data;
      });
      DashboardService.fetchDashboard('pf').then(res => {
        this.dadosPf = res.data;
      });
    },
  };
  </script>
  
  <style>
  .row {
    margin: 0;
    padding: 0;
  }
  </style>