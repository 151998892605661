<template>
  <div :id="id">

    <b-container fluid style="overflow: auto">
      <h4 class="color-primary-dark text-center mt-4 mb-4">{{titulo}}</h4>
      <table class="table table-striped tbl-expressoes">
        <thead>
          <tr>
            <td>Expression</td>
            <td>What's Mean</td>
            <td class="text-center icon-column">Editar</td>
            <td class="text-center icon-column">Excluir</td>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="exp in expressoes"
            :key="exp._id"
          >
            <td>{{getField(exp, "Expression")}}</td>
            <td>{{getField(exp, "What's Mean")}}</td>

            <td class="text-center icon-column">
              <b-button
                variant="light"
                @click="editExpressao(exp)"
              >
                <font-awesome-icon
                  :icon="['fas', 'pencil-alt']"
                  class="text-danger"
                />
              </b-button>
            </td>

            <td class="text-center icon-column">
              <b-button
                variant="light"
                @click="deleteExpressao(exp._id)"
                :disabled="deleting.includes(exp._id)"
              >
                <font-awesome-icon
                  :icon="['fas', 'spinner']"
                  spin
                  v-if="deleting.includes(exp._id)"
                />
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  class="text-danger"
                  v-else
                />
              </b-button>
            </td>
          </tr>

        </tbody>

      </table>
    </b-container>

    <modal-expressao
      ref="modalExpressao"
      :titulo="titulo"
      :id="id"
      @update="fetchData()"
    />

    <float-button-aux
      label="Nova Expressão"
      icon="plus"
      :id="id+'_'"
      v-b-modal="'modal-expressao_'+id"
    />

  </div>
</template>

<script>
import FloatButtonAux from "@/components/FloatButtonAux";
import ModalExpressao from "@/components/expressoes/ModalExpressao.vue";
import * as ExpressaoService from "@/services/ExpressaoService";

export default {
  props: ["id", "titulo"],
  data: () => ({
    expressoes: [],
    deleting: [],
  }),
  components: {
    FloatButtonAux,
    ModalExpressao,
  },
  methods: {
    fetchData() {
      ExpressaoService.fetchByType(this.id)
        .then((res) => {
          this.expressoes = res.data;
        })
        .catch(() =>
          this.$swal(
            "Erro",
            "Ocorreu um erro ao carregar as expressões, fale com o administrador do sistema",
            "error"
          )
        );
    },
    getField(expressao, campo) {
      return expressao.valores.find((x) => x.campo == campo).valor;
    },
    editExpressao(expressao) {
      this.$refs.modalExpressao.showEdit(expressao);
    },
    deleteExpressao(id) {
      this.deleting.push(id);

      ExpressaoService.remove(id)
        .then(() => {
          this.fetchData();
        })
        .catch(() => {
          this.$swal(
            "Erro",
            "Ocorreu um erro ao apagar a expressão, fale com o administrador do sistema",
            "error"
          );
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.tbl-expressoes thead {
  background: #7c7c7c;
  color: #fff;
  font-weight: bold;
}

.tbl-expressoes td {
  overflow-wrap: break-word;
}

.icon-column {
  width: 100px;
}
</style>