<template>
  <div>
    <div class="table-responsive">
      <table class="table mt-3 table-bordered table-renda">
        <thead>
          <tr>
            <th>Descrição</th>
            <th>Valor</th>
            <th class='text-center'>*</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="renda in rendas"
            :key="renda._id"
          >
            <td>{{renda.descricao}}</td>
            <td>R$ {{renda.valor | currency}}
              <span v-if="renda.valorRestante">
                <a
                  :id="`popover-${renda._id}`"
                  href="javascript:void(0)"
                  class="text-danger"
                > (Falta R$ {{renda.valorRestante | currency }})</a>
                <b-popover
                  :target="`popover-${renda._id}`"
                  placement="bottom"
                  title="Alterar Valor Restante"
                  triggers="click"
                >
                  <div class="d-flex">
                    <b-input
                      v-model="renda.novoValorRestante"
                      class="mr-3"
                      placeholder="0,00"
                      type="number"
                    />
                    <b-btn
                      variant="light"
                      @click="alterarValorRestante(renda)"
                    >
                      Ok
                    </b-btn>
                  </div>
                </b-popover>

              </span>
            </td>
            <td class="text-center">
              <b-button
                variant="danger"
                @click="deletar(renda._id)"
                :disabled="deleting.includes(renda._id)"
                size="sm"
              >
                <font-awesome-icon
                  :icon="['fas', 'spinner']"
                  spin
                  v-if="deleting.includes(renda._id)"
                />
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  v-else
                />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-alert
      variant="dark"
      :show="rendas.length == 0"
    >
      Nada encontrado
    </b-alert>
  </div>
</template>

<script>
import * as RendaService from "@/services/RendaService";
import { mapState } from "vuex";

export default {
  props: ["rendas"],
  data: () => ({
    deleting: []
  }),
  computed: {
    ...mapState(["caixas"])
  },
  methods: {
    deletar(id) {
      this.deleting.push(id);

      RendaService.remove(id)
        .then(() => {
          this.$emit("onDelete");
        })
        .catch(() => {
          this.$swal(
            "Erro",
            "Ocorreu um erro ao apagar a despesa, fale com o administrador do sistema",
            "error"
          );
        })
        .finally(() => {
          // Remove da array q está deletando
          this.deleting.filter(d => d != id);
        });
    },
    alterarValorRestante(renda) {
      RendaService.alteraValorRestante({
        ...renda,
        valorRestante: renda.novoValorRestante || 0
      })
        .then(res => {
          this.$root.$emit("bv::hide::popover");
          this.$emit("onUpdate", res.data);
        })
        .catch(() => {
          this.$swal(
            "Erro",
            "Ocorreu um erro ao editar o valor, fale com o administrador do sistema",
            "error"
          );
        });
    }
  }
};
</script>

<style>
.table-renda tr td {
  vertical-align: middle !important;
}
</style>