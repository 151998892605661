import api from ".";

export function add(lembrete) {
  return api.post("/lembrete", lembrete);
}

export function fetchByDay(ano, mes) {
  return api.get(`/lembrete/day/${ano}/${mes}`);
}

export function marcarComoDone(id) {
  return api.put(`/lembrete/${id}/marcarComoDone`);
}

export function remove(id) {
  return api.delete(`/lembrete/${id}`);
}

export function editLembrete(id, lembrete) {
  return api.put(`/lembrete/${id}`, lembrete);
}