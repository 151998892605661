import api, { getAuthorization } from './'

export function fetch(perPage, page) {
  return api.get('/aluno')
}

export function add(data) {
  return api.post('/aluno', data)
}

export function generateAuth(nome) {
  return api.get('/aluno/generateAuth', { params: { nome } })
}

export function remove(id) {
  return api.delete(`/aluno/${id}`)
}

export function getById(id) {
  return api.get(`/aluno/${id}`)
}

export function edit(dadosAluno) {
  return api.put(`/aluno/${dadosAluno._id}`, dadosAluno)
}

export function getAutocompleteItems() {
  return api.get('/aluno/autocomplete')
}

export function informarPagamento(idAluno, data) {
  return api.post(`/aluno/${idAluno}/pagamento`, data)
}

export function informarDesistencia(idAluno, data) {
  return api.post(`/aluno/${idAluno}/desistencia`, { data })
}