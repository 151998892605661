<template>
  <div id="app">

    <navbar v-if="this.$route.path != '/login' && this.$store.state.account.user.admin"/>

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  components: {
    Navbar
  },
  mounted(){

    const storagedUser = localStorage.getItem('USER_INFO')

    if(storagedUser){
      this.$store.commit('account/login', JSON.parse(storagedUser))
    }

  },
};
</script>

<style lang="scss">
#app {
  font-family: "Segoe Ui", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#app,
html,
body {
  background: #f3f3f3;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.09s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>
