<template>
  <b-container
    fluid
    class="mt-3"
  >

    <h2 class="color-primary-dark text-center">Lembretes</h2>

    <div
      class="mx-auto mt-3"
      style="max-width: 500px"
    >
      <month-selector
        @dateChanged="fetchData"
        :selectedDate.sync="date"
      ></month-selector>
    </div>

    <hr />

    <b-row>
      <b-col>
        <form-lembrete
          @novoLembrete="setDate"
          :anoReferencia="date.anoReferencia"
          :mesReferencia="date.mesReferencia"
        ></form-lembrete>
      </b-col>

    </b-row>

    <hr />

    <b-row class="mb-5">
      <b-col class="col-lembretes">

        <div class="d-flex justify-content-center flex-wrap">
          <mapa-lembretes-dia
            v-for="(data, i) in lembretes"
            :key="i"
            :dia="data.dia"
            :lembretes="data.lembretes"
            @done="updateDone"
            @updateDate="fetchData"
          />
        </div>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import FormLembrete from "@/components/lembretes/FormLembrete";
import MonthSelector from "@/components/MonthSelector";
import MapaLembretesDia from "@/components/lembretes/MapaLembretesDia.vue";

import * as LembreteService from "@/services/LembreteService";

export default {
  components: {
    FormLembrete,
    MonthSelector,
    MapaLembretesDia,
  },
  data: () => ({
    lembretes: [],
    date: {
      anoReferencia: new Date().getFullYear(),
      mesReferencia: new Date().getMonth() + 1,
    },
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    setDate(date) {
      this.date = date;
      this.fetchData();
    },
    fetchData() {
      const { anoReferencia, mesReferencia } = this.date;

      LembreteService.fetchByDay(
        anoReferencia,
        mesReferencia.toString().padStart(2, "0")
      )
        .then((res) => {
          this.lembretes = res.data.lembretes;
        })
        .catch(() =>
          this.$swal(
            "Erro",
            "Ocorreu um erro ao carregar os lembretes, fale com o administrador do sistema",
            "error"
          )
        );
    },
    updateDone(lembrete) {
      this.lembretes
        .find((x) => x.dia == lembrete.dia)
        .lembretes.find((x) => x._id == lembrete._id).done = lembrete.done;
      this.$forceUpdate();
    },
  },
  watch: {
    ["date.anoReferencia"]() {
      this.fetchData();
    },
    ["date.mesReferencia"]() {
      this.fetchData();
    },
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .col-lembretes {
    padding: 0px !important;
  }
}
</style>