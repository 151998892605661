<template>
  <div class="shadow p-4 m-3 bg-white mapa-pagamentos-dia">
    <table class="table table-striped">
      <thead>
        <tr>
          <td
            colspan="5"
            class="text-center font-weight-bold color-primary-dark"
          >Dia {{dia}}</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(despesa, i) in despesas"
          :key="i"
          :class="{ 
            'text-success': despesa.pago, 
            pago: despesa.pago, 
           }"
        >
          <td style="overflow-wrap: anywhere; min-width: 80px">{{despesa.nome}}</td>

          <td class="text-right">R$ {{despesa.valor | currency}}</td>

          <td
            class="text-right"
            v-if="despesa.pago"
          >PAGO</td>

          <td
            class="text-center"
            v-else
          >
            <b-btn
              :id="`popover-${despesa._id}`"
              variant="light"
            >
              <font-awesome-icon
                :icon="['fas', 'check']"
                class="text-success btn-apagar"
              />
            </b-btn>

            <b-popover
              :target="`popover-${despesa._id}`"
              placement="bottom"
              title="Pagar"
              triggers="hover focus"
            >
              <div class="d-flex">
                <b-form-select
                  v-model="despesa.caixa"
                  class="mr-2"
                >
                  <option
                    :value="null"
                    disabled
                  >Selecione o Caixa</option>
                  <option
                    v-for="caixa in caixas.caixas.filter(caixa => caixa.perfil == perfil)"
                    :value="caixa.id"
                    :key="caixa.id"
                  >{{caixa.nome}}</option>
                </b-form-select>

                <b-btn
                  variant="light"
                  @click="pagar(despesa)"
                >Ok</b-btn>
              </div>
            </b-popover>
          </td>

          <td class="text-center icon-column">
            <b-button
              v-if="!despesa.pago"
              style="color: #676060"
              variant="light"
              @click="editDespesa(despesa)"
            >
              <font-awesome-icon :icon="['fas', 'pencil-alt']" />
            </b-button>
          </td>

          <td class="text-center">
            <b-button
              variant="light"
              @click="deleteDespesa(despesa._id)"
              :disabled="deleting.includes(despesa._id)"
            >
              <font-awesome-icon
                :icon="['fas', 'spinner']"
                spin
                v-if="deleting.includes(despesa._id)"
              />
              <font-awesome-icon
                :icon="['fas', 'trash']"
                class="text-danger"
                v-else
              />
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <hr />

    <h6 class="text-right text-danger">Total: R$ {{total | currency}}</h6>

    <modal-despesa
      ref="modalDespesa"
      @onsave="updateDate"
    />

  </div>
</template>

<script>
import * as DespesaService from "@/services/DespesaService";
import ModalDespesa from "./ModalDespesa.vue";
import { mapState } from "vuex";

export default {
  props: ["dia", "despesas", "total"],
  components: {
    ModalDespesa,
  },
  data() {
    return {
      deleting: [],
      perfil: this.$store.state.perfil.perfil
    };
  },
  computed: mapState(["caixas"]),
  methods: {
    pagar(despesa) {
      DespesaService.pay(despesa._id, despesa.caixa).then((res) => {
        this.$emit("pay", res.data);
      });
    },
    deleteDespesa(id) {
      this.deleting.push(id);

      DespesaService.remove(id)
        .then(() => {
          this.$emit("updateDate");
        })
        .catch(() => {
          this.$swal(
            "Erro",
            "Ocorreu um erro ao apagar a despesa, fale com o administrador do sistema",
            "error"
          );
        })
        .finally(() => {
          // Remove da array q está deletando
          this.deleting.filter((d) => d != id);
        });
    },
    editDespesa(despesa) {
      this.$refs.modalDespesa.showEdit(despesa);
    },
    updateDate() {
      this.$emit("updateDate");
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  color: #555;

  tbody td {
    padding: 8px;
    font-size: 16px !important;
    vertical-align: middle;
  }

  tbody tr td {
    cursor: pointer;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.pago {
  background: #f2fff2 !important;
}

.valor-pago {
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  .mapa-pagamentos-dia {
    flex-grow: 1;
    width: 100%;
    padding: 15px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
</style>