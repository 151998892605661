<template>
  <b-form>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Nome do Lembrete"
          class="form-label"
        >
          <b-form-input
            type="text"
            v-model="nome"
            placeholder="Digite o nome do lembrete"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          label="Dia"
          class="form-label"
        >
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text">Dia</span>
            </b-input-group-prepend>
            <b-form-input
              placeholder="e.g 15"
              v-model="dia"
              type="number"
              min="1"
              max="31"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-center">
        <b-form-checkbox v-model="done">
          Marcar como Done
        </b-form-checkbox>
      </b-col>

      <b-col>
        <b-button
          class="float-right"
          variant="primary-dark"
          @click="submitLembrete"
        >Salvar Lembrete</b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import * as LembreteService from "@/services/LembreteService";

export default {
  props: ["anoReferencia", "mesReferencia"],
  data: () => ({
    nome: "",
    dia: new Date().getDate(),
    done: false,
  }),
  methods: {
    submitLembrete() {
      const {
        nome,
        anoReferencia,
        mesReferencia,
        dia,
        done,
      } = this;
      if (nome == "") {
        this.$swal("Erro", "Insira o nome do lembrete", "error");
      } else if (dia > 31 || dia < 1 || !(/^\d+$/.test(dia))){
        this.$swal("Erro", "Insira um dia válido", "error");
      }
      else {
        LembreteService.add({
          nome,
          done,
          dia,
          mesReferencia: `${mesReferencia
            .toString()
            .padStart(2, "0")}/${anoReferencia}`,
        })
          .then(() => {
            this.$swal("Sucesso", "O lembrete foi salvo", "success");
            this.nome = "";
            this.done = false;
            this.$emit("novoLembrete", { mesReferencia, anoReferencia });
          })
          .catch(() => {
            this.$swal(
              "Erro",
              "Ocorreu um erro ao salvar o lembrete, fale com o administrador do sistema",
              "error"
            );
          });
      }
    },
  },
};
</script>

<style lang="scss">
.form-label {
  font-weight: bold;
  color: $primary-dark;
}
</style>