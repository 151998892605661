<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    class="aluno-navbar"
  >
    <b-navbar-brand href="#">Teacher Reh • Área do Aluno</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template v-slot:button-content>
            <em>Olá, {{nomeAluno}}</em>
          </template>
          <b-dropdown-item
            href="#"
            @click.prevent="loggoutAccount()"
          >Sair da Conta</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  props: ['nomeAluno'],
  methods: {
    loggoutAccount() {
      this.$store.commit("account/loggout");
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="scss">
.aluno-navbar {
  background: $primary-dark;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}
</style>