<template>
  <b-container
    fluid
    class="mt-3"
  >

    <h2 class="color-primary-dark text-center">Despesas</h2>

    <div
      class="mx-auto mt-3"
      style="max-width: 500px"
    >
      <month-selector
        @dateChanged="fetchData"
        :selectedDate.sync="date"
      ></month-selector>
    </div>

    <b-row>
      <b-col md="6" class="mx-auto text-center pr-3">
        <b-form-group>
          <b-form-select v-model="filtro" @change="fetchData()">
            <option :value=null>Todas as despesas</option>
            <option :value=false>Despesas em aberto</option>
            <option :value=true>Despesas pagas</option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="mx-auto text-right pr-3">
      <b-btn
        variant="light"
        class="right"
        style="color: #555"
        @click="adicionarFixas()"
      >
        <font-awesome-icon
          :icon="['fas', 'money-bill']"
          class="icon"
        />
        Adicionar despesas fixas ao mês
      </b-btn>
    </div>

    <hr />

    <b-row>
      <b-col>
        <form-despesa
          @novaDespesa="setDate"
          :anoReferencia="date.anoReferencia"
          :mesReferencia="date.mesReferencia"
        ></form-despesa>
      </b-col>

    </b-row>

    <hr />

    <b-row class="mb-5">
      <b-col class="col-despesas">

        <!-- <tabela-despesas
          :despesas="despesas"
          @onDelete="fetchData"
          @pay="updatePay"
        ></tabela-despesas> -->

        <div class="d-flex justify-content-center flex-wrap">
          <mapa-despesas-dia
            v-for="(data, i) in despesas"
            :key="i"
            :dia="data.dia"
            :despesas="data.despesas"
            :total="data.total"
            @pay="updatePay"
            @updateDate="fetchData"
          />
        </div>

        <hr />

        <div
          class="p-3 m-3 bg-white mx-auto"
          style="width: 300px"
        >
          <h5
            class="text-danger mb-0 text-center"
            style="font-size: 18px"
          >
            Total: R$ {{ totalDespesas | currency }}
          </h5>

        </div>

      </b-col>

    </b-row>

    <modal-despesas-fixas />

    <float-button
      label="Despesas Fixas"
      icon="wallet"
      v-b-modal.modal-despesas-fixas
    ></float-button>
  </b-container>
</template>

<script>
import TabelaDespesas from "@/components/despesas/TabelaDespesas";
import FormDespesa from "@/components/despesas/FormDespesa";
import MonthSelector from "@/components/MonthSelector";
import ModalDespesasFixas from "@/components/despesas/ModalDespesasFixas";
import FloatButton from "@/components/FloatButton";
import MapaDespesasDia from "@/components/despesas/MapaDespesasDia";

import * as DespesaService from "@/services/DespesaService";

export default {
  components: {
    TabelaDespesas,
    FormDespesa,
    MonthSelector,
    FloatButton,
    ModalDespesasFixas,
    MapaDespesasDia,
  },
  data: () => ({
    despesas: [],
    filtro: null,
    totalDespesas: 0,
    date: {
      anoReferencia: new Date().getFullYear(),
      mesReferencia: new Date().getMonth() + 1,
    },
  }),
  mounted() {
    this.filtro = this.$route.params.pago ? this.$route.params.pago : null
    this.fetchData();
  },
  methods: {
    setDate(date) {
      this.date = date;
      this.fetchData();
    },
    fetchData() {
      const { anoReferencia, mesReferencia } = this.date;

      DespesaService.fetchByDay(
        anoReferencia,
        mesReferencia.toString().padStart(2, "0"),
        this.filtro
      )
        .then((res) => {
          this.despesas = res.data.despesas;
          this.totalDespesas = res.data.total;
        })
        .catch(() =>
          this.$swal(
            "Erro",
            "Ocorreu um erro ao carregar as despesas, fale com o administrador do sistema",
            "error"
          )
        );
    },
    updatePay(despesa) {
      this.despesas
        .find((x) => x.dia == despesa.dia)
        .despesas.find((x) => x._id == despesa._id).pago = despesa.pago;
      this.$forceUpdate();
    },
    adicionarFixas() {
      const { anoReferencia, mesReferencia } = this.date;
      const dataReferencia = `${mesReferencia
        .toString()
        .padStart(2, "0")}/${anoReferencia}`;

      this.$swal({
        title: "Despesas Fixas",
        text: `Deseja realmente adicionar as despesas fixa ao mês ${dataReferencia}?`,
        type: "question",
        showCancelButton: true,
      }).then(async (r) => {
        if (r.value) {
          try {
            var mes = mesReferencia.toString().padStart(2, "0");

            await DespesaService.insertFixas(anoReferencia, mes);

            await this.fetchData();
          } catch (e) {
            console.log(e);

            this.$swal(
              "Erro",
              "Ocorreu um erro ao adicionar as despesas fixas. Fale com o Administrador",
              "error"
            );
          }
        }
      });
    },
  },
  watch: {
    ["date.anoReferencia"]() {
      this.fetchData();
    },
    ["date.mesReferencia"]() {
      this.fetchData();
    },
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .col-despesas {
    padding: 0px !important;
  }
}
</style>