import * as AlunoService from '@/services/AlunoService'

const alunosModule = {

  namespaced: true,

  state: {
    loading: false,
    editing: null,
    items: []
  },
  mutations: {
    fetchRequest(state){
      // Loading
      state.loading = true
    },
    fetchSuccess(state, { page, total, alunos }){
      // Adiciona itens ao state
      state.loading = false;
      state.items = alunos
    },
    remove(state, { id }){
      // Remove do state
      state.items = state.items.filter(a => a._id != id)
    },
    beginEdit(state, aluno){
      // Converte pra data certa
      aluno.dtNascimento = new Date(aluno.dtNascimento)
      aluno.dtInicio = new Date(aluno.dtInicio)
      aluno.dtFim = new Date(aluno.dtFim)

      // Adiciona no State
      state.editing = aluno
    }
  },
  actions: {
    add({ commit }, payload){
      // Adicionar Aluna
      return AlunoService.add(payload)
    },
    fetch({ commit }){
      // Pegar alunos
      commit('fetchRequest')
      return AlunoService.fetch()
        .then(res => {
          const alunos = res.data.items;
          commit('fetchSuccess', { alunos })
        })
    },
    remove({ commit }, { id }){
      // Remover Aluno
      return AlunoService.remove(id)
        .then(() => {
          commit('remove', { id })
        })
    },
    fetchEditing({ commit }, { id }){
      // Pegar aluno para editar
      return AlunoService.getById(id)
        .then(res => {
          console.log(res)
          commit('beginEdit', res.data)
        })
    },
    edit({ commit }, dadosAluno){
      // Editar aluno
      return AlunoService.edit(dadosAluno)
    }
  }
}

export default alunosModule