export function removeTimeSeconds(hora) {
  var separated = hora.split(":");
  if (separated.length >= 2) {
    separated = [separated[0].padStart(2, "0"), separated[1].padStart(2, "0")];
  }
  return separated.join(":");
}

export function formatHour(hora) {
  return hora.replace(":", "h");
}
