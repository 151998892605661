<template>
  <b-container
    fluid
    class="mt-3"
  >
    <b-row>
      <b-col md="6">
        <h3>Renda</h3>
        <br />
        <form-renda @novaRenda="setDate"></form-renda>
      </b-col>
      <b-col md="6">
        <h3>Histórico</h3>
        <br />
        <month-selector
          @dateChanged="fetchData"
          :selectedDate.sync="date"
        ></month-selector>

        <tabela-renda
          :rendas="rendas"
          @onDelete="fetchData"
          @onUpdate="fetchData"
        ></tabela-renda>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TabelaRenda from "@/components/renda/TabelaRenda";
import FormRenda from "@/components/renda/FormRenda";
import MonthSelector from "@/components/MonthSelector";

import * as RendaService from "@/services/RendaService";
import store from "@/store";

export default {
  components: {
    TabelaRenda,
    FormRenda,
    MonthSelector
  },
  data: () => ({
    rendas: [],
    date: {
      anoReferencia: new Date().getFullYear(),
      mesReferencia: new Date().getMonth() + 1
    }
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    setDate(date) {
      this.date = date;
      this.fetchData();
    },
    fetchData() {
      const { anoReferencia, mesReferencia } = this.date;

      RendaService.fetch(
        anoReferencia,
        mesReferencia.toString().padStart(2, "0")
      )
        .then(res => {
          const perfil = store.state.perfil.perfil;
          let rendas = res.data.rendas;
          this.rendas = rendas.filter(renda => renda.perfil == perfil);
        })
        .catch(() =>
          this.$swal(
            "Erro",
            "Ocorreu um erro ao carregar as rendas, fale com o administrador do sistema",
            "error"
          )
        );
    }
  },
  watch: {
    ["date.anoReferencia"]() {
      this.fetchData();
    },
    ["date.mesReferencia"]() {
      this.fetchData();
    }
  }
};
</script>

<style>
</style>