<template>
  <b-modal
    id="modal-senha"
    title="Gerenciar senha"
    size="lg"
    hide-footer
    ref="modalSenha"
    @hide="refresh()"
  >
    <b-form-group>
      <b-form-input placeholder="Sistema" v-model="sistema" />
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Usuário" v-model="usuario" />
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Senha" v-model="password" />
    </b-form-group>

    <div class="text-right">
      <b-button @click="salvar" variant="primary-dark">Salvar</b-button>
    </div>
  </b-modal>
</template>

<script>
import * as SistemaService from "@/services/SistemaService";
import store from "@/store";

export default {
  components: {},
  data: () => ({
    id: null,
    sistema: "",
    usuario: "",
    password: ""
  }),
  methods: {
    salvar() {
      const { sistema, usuario, password } = this;

      if (!sistema) {
        this.$swal("Erro", "Preencha o campo 'Sistema'", "error");
      } else if (!usuario) {
        this.$swal("Erro", "Preencha o campo 'Usuário'", "error");
      } else if (!password) {
        this.$swal("Erro", "Preencha o campo 'Senha'", "error");
      } else {
        var reqPromise;
        if (this.id)
          reqPromise = SistemaService.update(this.id, this.getData());
        else reqPromise = SistemaService.add(this.getData());

        reqPromise
          .then(() => {
            this.$refs.modalSenha.hide();
            this.$emit("update");
            this.id = null;
            this.sistema = "";
            this.usuario = "";
            this.password = "";
          })
          .catch(() => {
            this.$swal(
              "Erro",
              "Ocorreu um erro ao salvar, fale com o administrador",
              "error"
            );
          });
      }
    },
    getData() {
      return {
        _id: this.id,
        sistema: this.sistema,
        usuario: this.usuario,
        password: this.password,
        perfil: store.state.perfil.perfil
      };
    },
    showEdit(sistema) {
      this.sistema = sistema.sistema;
      this.password = sistema.password;
      this.usuario = sistema.usuario;
      this.id = sistema._id;
      this.$refs.modalSenha.show();
    },
    refresh() {
      this.id = null;
      this.sistema = "";
      this.usuario = "";
      this.password = "";
      this.$emit("update");
    }
  }
};
</script>

<style>
</style>