import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

// Views
import Home from "../views/Home.vue";
import Alunos from "../views/Alunos.vue";
import CadastroAluno from "../views/CadastroAluno.vue";
import Caixas from "../views/Caixas.vue";
import Agendamento from "../views/Agendamento.vue";
import Pagamentos from "../views/Pagamentos.vue";
import Renda from "../views/Renda.vue";
import Despesas from "../views/Despesas.vue";
import Dashboard from "../views/Dashboard.vue";
import Relatorio from "../views/Relatorio.vue";
import Agenda from "../views/Agenda.vue";
import ExpressoesMain from "../views/ExpressoesMain.vue";
import Sistemas from "../views/Sistemas.vue";
import Lembretes from "../views/Lembretes.vue";

import Developening from "../views/Developening";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Home
  },
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      permissions: ['pj', 'pf']
    }
  },
  {
    path: "/alunos",
    name: "alunos",
    component: Alunos,
    meta: {
      permissions: ['pj']
    }
  },
  {
    path: "/alunos/cadastro",
    name: "cadastroAluno",
    component: CadastroAluno,
    meta: {
      permissions: ['pj']
    }
  },
  {
    path: "/alunos/cadastro/:id",
    name: "editarAluno",
    component: CadastroAluno,
    meta: {
      permissions: ['pj']
    }
  },
  {
    path: "/caixas",
    name: "caixas",
    component: Caixas,
    meta: {
      permissions: ['pj', 'pf']
    }
  },
  {
    path: "/agendamento",
    name: "agendamento",
    component: Agendamento,
    meta: {
      permissions: ['pj']
    }
  },
  {
    path: "/despesas/:pago?",
    name: "despesas",
    component: Despesas,
    meta: {
      permissions: ['pj', 'pf']
    }
  },
  {
    path: "/relatorio",
    name: "relatorio",
    component: Relatorio,
    meta: {
      permissions: ['pj']
    }
  },
  {
    path: "/pagamentos",
    name: "pagamentos",
    component: Pagamentos,
    meta: {
      permissions: ['pj']
    }
  },
  {
    path: "/renda",
    name: "renda",
    component: Renda,
    meta: {
      permissions: ['pj', 'pf']
    }
  },
  {
    path: "/agenda",
    name: "agenda",
    component: Agenda,
    meta: {
      permissions: ['pj']
    }
  },
  {
    path: "/expressoes",
    name: "expressoes",
    component: ExpressoesMain,
    meta: {
      permissions: ['pj']
    }
  },
  {
    path: "/sistemas",
    name: "Sistemas",
    component: Sistemas,
    meta: {
      permissions: ['pj', 'pf']
    }
  },
  {
    path: "/lembretes",
    name: "lembretes",
    component: Lembretes,
    meta: {
      permissions: ['pj']
    }
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((from, to, next) => {  
  if (from.name != "login") {
    const userToken = localStorage.getItem("USER_INFO");

    if (userToken) {
      if (from.meta.permissions.includes(store.state.perfil.perfil)){
        next();
      }else{
        next({name: "dashboard"});
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
