import api from './'

export function fetchPendentes() {
  return api.get('/pagamento/pendente?groupByDay=true')
}

export function fetchPendentesPorDia(proximo, data) {
  return api.get(`/pagamento/pendentePorDia/${proximo}/${data}`);
}

export function obtemRecibo(info) {
  return api.post("/pagamento/obtemRecibo", info);
}

export function remove(id) {
  return api.delete(`/pagamento/${id}`)
}