<template>
  <b-col md="6">
    <h5>
      <font-awesome-icon :icon="['fas', 'calendar-week']" />
      Agendamento
    </h5>

    <table class="table mt-3 table-bordered">
      <thead>
        <tr class="text-danger">
          <th>Dia da Semana</th>
          <th>Horário Inicio</th>
          <th>Horário Término</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="diaAgenda in agendamento" :key="diaAgenda._id">
          <td>{{diaAgenda.dia}}</td>
          <td>{{diaAgenda.horaInicio}}</td>
          <td>{{diaAgenda.horaFim}}</td>
        </tr>
      </tbody>
    </table>
  </b-col>
</template>

<script>
export default {
  props: ['agendamento']
};
</script>

<style>
</style>