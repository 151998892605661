import { render, staticRenderFns } from "./DashboardAluno.vue?vue&type=template&id=40a7bbfd&"
import script from "./DashboardAluno.vue?vue&type=script&lang=js&"
export * from "./DashboardAluno.vue?vue&type=script&lang=js&"
import style0 from "./DashboardAluno.vue?vue&type=style&index=0&id=40a7bbfd&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports