<template>
  <div class="w-100">
    <b-alert variant="secondary" v-if="pendencias == null" class="text-center m-2 mt-5">
      <font-awesome-icon :icon="['fas', 'spinner']" spin />
    </b-alert>
    <div class="w-100" v-else>
      <modal-atrasos :aluno="alunoSelecionado" @toggleRefresh="handleToggleRefresh" />

      <div class="d-flex justify-content-center flex-wrap w-100">
        <mapa-pagamentos-dia
          v-for="(dia, i) in pendencias.pagamentos"
          :key="i"
          :dia="dia.dia"
          :alunos="dia.alunos"
          :valor-esperado="dia.valorEsperado"
          :valor-recebido="dia.valorRecebido"
          :valor-restante="dia.valorRestante"
          :proximo-mes="proximoMes"
          @toggleAtrasos="handleToggleAtrasos"
        />
      </div>

      <hr class="mx-auto mb-3" />

      <b-card class="card-total w-100 mb-5">
        <b-card-title class="color-primary-dark">Total Geral</b-card-title>
        <informe-total label="Valor Esperado" :valor="pendencias.valorEsperado" />
        <hr class="m-2" />
        <informe-total label="Valor Recebido" :valor="pendencias.valorRecebido" />
        <hr class="m-2" />
        <informe-total label="Valor Restante" :valor="pendencias.valorRestante" />
      </b-card>
    </div>
  </div>
</template>

<script>
import * as PagamentoService from "@/services/PagamentoService";
import MapaPagamentosDia from "./MapaPagamentosDia";
import ModalAtrasos from "./ModalAtrasos";
import InformeTotal from "./InformeTotal";
export default {
  components: {
    MapaPagamentosDia,
    ModalAtrasos,
    InformeTotal
  },
  data() {
    return {
      pendencias: null,
      alunoSelecionado: null,
      proximoMes: false,
    };
  },
  methods: {
    handleToggleAtrasos(aluno) {
      this.alunoSelecionado = aluno;
      this.$bvModal.show("modal-atrasos");
    },
    handleToggleRefresh(alunoEnviado) {
      this.$bvModal.hide("modal-atrasos");
      PagamentoService.fetchPendentesPorDia().then(res => {
        this.pendencias = res.data;
        this.pendencias.pagamentos.forEach(dia => {
          dia.alunos.forEach(aluno => {
            if (aluno._id == alunoEnviado._id) this.alunoSelecionado = aluno;
          });
        });
        this.$bvModal.show("modal-atrasos");
      });
    },
    buscarPendentes(proximoMes, dataSelecionada) {
      this.pendencias= [];
      this.proximoMes = proximoMes;
      PagamentoService.fetchPendentesPorDia(proximoMes, dataSelecionada).then(res => {
        this.pendencias = res.data;
      });
    }
  },
  mounted() {
    this.buscarPendentes(false, null);
  }
};
</script>