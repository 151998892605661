<template>
  <div class="mt-5">
    <full-calendar
      style="min-height: 500px"
      :events="events"
      :config="config"
      @day-click="dayClick"
      @event-selected="(x) => dayClick(x.start)"
      ref="fullCalendar"
    ></full-calendar>

    <modal-dia-agenda
      :data="dia"
      :eventos="eventosDia"
      @novoEvento="criarEvento"
      @deleteEvento="deleteEvento"
    />
  </div>
</template>

<script>
import * as AgendaService from "@/services/AgendaService";
import ModalDiaAgenda from "@/components/agenda/ModalDiaAgenda";

export default {
  components: {
    ModalDiaAgenda,
  },
  data() {
    return {
      dia: null,
      events: [],
      config: {
        defaultView: "month",
      },
    };
  },
  computed: {
    eventosDia() {
      if (this.dia) {
        return this.events.filter(
          (x) =>
            this.$moment(x.start).format("YYYY-MM-DD") ==
            this.$moment(this.dia).format("YYYY-MM-DD")
        );
      }
    },
  },
  methods: {
    mapEvent(x) {
      return {
        id: x._id,
        title: x.descricao,
        start: this.$moment.utc(x.data).format("YYYY-MM-DD"),
        allDay: true,
      };
    },
    dayClick(day) {
      const date = new Date();
      date.setDate(day.format("D"));
      date.setMonth(day.format("M") - 1);
      date.setFullYear(day.format("Y"));
      this.dia = date;
      this.$bvModal.show("modal-dia-agenda");
    },
    criarEvento(evento) {
      AgendaService.add(evento).then((res) => {
        this.events.push(this.mapEvent(res.data));
      });
    },
    updateEvents() {
      const el = this.$refs.fullCalendar.$el;

      const d = this.$moment.utc(
        el.querySelector(".fc-day:not(.fc-other-month)").attributes["data-date"]
          .value
      );

      AgendaService.get(d.format("YYYY"), d.format("MM")).then((res) => {
        this.events = res.data.map(this.mapEvent);
      });
    },
    deleteEvento(id) {
      AgendaService.remove(id).then(() => {
        this.events = this.events.filter((x) => x.id != id);
      });
    },
  },
  mounted() {
    const el = this.$refs.fullCalendar.$el;
    el.querySelector(".fc-prev-button").onclick = this.updateEvents;
    el.querySelector(".fc-next-button").onclick = this.updateEvents;
    this.updateEvents();
  },
};
</script>

<style lang="scss">
#calendar {
  margin-top: 80px;

  h2 {
    color: $primary-dark;
    margin-top: -100px;
  }

  .fc-today-button {
    display: none;
  }

  .fc-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .fc-right {
    display: none;
  }

  .fc-button {
    background: #fff;
    border: none;
    color: $primary-dark;
    text-shadow: none;
  }

  .fc-button:focus {
    outline-color: $primary-dark;
  }

  .fc-event,
  .fc-event-container {
    border: $primary-dark;
    background: $primary-dark;
    padding: 2px;
  }

  .fc-event-container {
    border: solid 1px #fff;
  }

  .fc-title {
    color: #fff;
  }

  .fc-day-header {
    background: $primary-dark;
    color: #fff;
    padding: 5px;
    border: none;
  }

  thead {
    border-color: $primary-dark;
  }

  tbody td {
    border-color: #fff;
    color: #777;
    cursor: pointer;
  }

  .fc-day-grid-container {
    min-height: 385px;
  }

  .fc-today {
    background: #fff;
  }

  .fc-highlight {
    background: none;
  }
}
</style>