<template>
  <b-modal
    id="modal-pagamentos-aluno"
    title="Pagamentos"
    size="lg"
    ok-only
  >
    <pagamentos-aluno 
      :all="true" 
      :pendencias="pendencias"
    />
  </b-modal>
</template>

<script>

import PagamentosAluno from "./PagamentosAluno";

export default {
  props: ['pendencias'],
  components: {
    PagamentosAluno
  }
};
</script>

<style>
</style>