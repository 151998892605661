<template>
  <div class="shadow p-4 m-3 bg-white mapa-pagamentos-dia">
    <table class="table table-striped">
      <thead>
        <tr>
          <td
            colspan="2"
            class="text-center font-weight-bold color-primary-dark"
          >
            Dia {{ dia }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          @click="handleAlunoClick(aluno)"
          v-for="(aluno, i) in alunos"
          :key="i"
          :class="{
            'text-success': aluno.pago,
            pago: aluno.pago,
            'text-info': pagamentoMaior(aluno),
            'pago-divergente-maior': pagamentoMaior(aluno),

            'text-warning': pagamentoMenor(aluno),
            'pago-divergente-menor': pagamentoMenor(aluno),

            'text-danger': aluno.possuiAtraso && !proximoMes,
            atrasado: aluno.possuiAtraso && !proximoMes,
          }"
        >
          <td>
            {{ aluno.nome }}
            <font-awesome-icon
              :icon="['fas', 'info-circle']"
              v-if="aluno.observacoesMes && aluno.observacoesMes.length"
            />
          </td>
          <td class="text-right" v-if="aluno.pago">
            PAGO
            <span class="valor-pago"
              >(R$ {{ aluno.pagamentoMes | currency }})</span
            >
          </td>
          <td class="text-right" v-else>
            R$ {{ aluno.pendencias.valor | currency }}
          </td>
        </tr>
      </tbody>
    </table>

    <hr style="width: 50%" class="mx-auto mb-3" />

    <informe-total label="Valor Esperado" :valor="valorEsperado" />
    <hr class="m-2" />
    <informe-total label="Valor Recebido" :valor="valorRecebido" />
    <hr class="m-2" />
    <informe-total label="Valor Restante" :valor="valorRestante" />
  </div>
</template>

<script>
import InformeTotal from "./InformeTotal";

export default {
  props: [
    "dia",
    "alunos",
    "valorEsperado",
    "valorRecebido",
    "valorRestante",
    "proximoMes",
  ],
  components: {
    InformeTotal,
  },
  methods: {
    pagamentoMaior(aluno) {
      return aluno.pago && this.diferencaPagamento(aluno) > 0;
    },
    pagamentoMenor(aluno) {
      return aluno.pago && this.diferencaPagamento(aluno) < 0;
    },
    diferencaPagamento({ valor, pagamentoMes }) {
      return pagamentoMes - valor;
    },
    handleAlunoClick(aluno) {
      this.$emit("toggleAtrasos", aluno);
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  color: #555;

  tbody td {
    padding: 8px;
    font-size: 16px !important;
  }

  tbody tr td {
    cursor: pointer;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.pago {
  background: #f2fff2 !important;
}

.pago-divergente-menor {
  background-color: rgba(255, 193, 7, 0.08) !important;
}

.pago-divergente-maior {
  background-color: rgba(7, 210, 255, 0.08) !important;
}

.valor-pago {
  white-space: nowrap;
}

.atrasado {
  background-color: rgba(255, 190, 187, 0.08) !important;
}

@media only screen and (max-width: 600px) {
  .mapa-pagamentos-dia {
    flex-grow: 1;
  }
}
</style>