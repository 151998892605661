<template>
  <div class="header-container">
    <b-row class="dashboard-title">
      <b-col md="12">
        <h4>Pessoa Física</h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <header-card title="Total Caixas">
          <h4>
            <b>{{ dadosPf.totalCaixas | currency }}</b>
          </h4>
        </header-card>
      </b-col>

      <b-col md="3">
        <header-card title="Total Despesas Abertas">
          <h4>
            <b>{{ dadosPf.totalDespesasAberto | currency }}</b>
          </h4>
        </header-card>
      </b-col>

      <b-col md="3">
        <header-card title="Total Despesas Pagas">
          <h4>
            <b>{{ dadosPf.totalDespesasPago | currency }}</b>
          </h4>
        </header-card>
      </b-col>
      <b-col md="3">
        <header-card title="Diferença">
          <h4>
            <b>{{ getDiferenca() | currency }}</b>
          </h4>
        </header-card>
      </b-col>
    </b-row>

    <b-row class="mt-4 dashboard-title">
      <b-col md="12">
        <h4>Pessoa Jurídica</h4>
      </b-col>
    </b-row>

    <b-row class="card-container w-100">
      <b-col md="4">
        <header-card title="Total Caixas">
          <h4>
            <b>{{ dadosPj.totalCaixas | currency }}</b>
          </h4>
        </header-card>
      </b-col>

      <b-col md="4">
        <header-card title="Total Despesas Abertas">
          <h4>
            <b>{{ dadosPj.totalDespesasAberto | currency }}</b>
          </h4>
        </header-card>
      </b-col>

      <b-col md="4">
        <header-card title="Total Despesas Pagas">
          <h4>
            <b>{{ dadosPj.totalDespesasPago | currency }}</b>
          </h4>
        </header-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeaderCard from "./header/HeaderCard";

export default {
  props: ["dadosPj", "dadosPf"],
  components: {
    HeaderCard,
  },
  methods: {
    /*Renda + Planejado mes atual - Total Despesas Abertas PJ - 
    Total despesas pagas PJ - Total Despesas Abertas PF - 
    Total Despesas Pagas PF*/
    getDiferenca() {
      return (
        this.dadosPf.rendaExtra +
        this.dadosPj.totalPlanejadoAlunos -
        this.dadosPj.totalDespesasAberto -
        this.dadosPj.totalDespesasPago -
        this.dadosPf.totalDespesasAberto -
        this.dadosPf.totalDespesasPago
      );
    },
  },
};
</script>

<style scoped lang="scss">
.header-container {
  width: 100%;
  min-height: 300px;
  background: $primary-dark;
  margin-top: -5px;
  position: relative;
  margin-bottom: 100px;
}

.header-col-click {
  cursor: pointer;

  & > div {
    transition: all 0.2s;
    &:hover,
    &:active {
      background: #f0f0f0;
    }
  }
}

.card-container {
  justify-content: center;
  position: absolute;
  bottom: -70px;
}

.contrast {
  border: 4px solid #e4c979;
}

.aniversariantes-container {
  max-height: 70px;
  overflow: auto;
}

@media screen and (max-width: 991px) {
  .card-container {
    position: initial;
  }

  .header-container {
    margin-bottom: 0;
    padding-bottom: 10px;
  }
}
.link {
  color: #2c3e50;
  text-decoration: none;
}
.dashboard-title {
  color: white;
}
</style>
