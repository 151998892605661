<template>
    <b-modal id="modal-editar-lembrete" title="Lembrete" size="lg" ref="modalEditarLembrete">
      <b-form>
        <b-row>
          <b-col md="8">
            <b-form-group label="Nome do Lembrete" class="form-label">
              <b-form-input type="text" v-model="properties.nome" placeholder="Digite o nome do lembrete" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Dia" class="form-label">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">Dia</span>
                </b-input-group-prepend>
                <b-form-input placeholder="e.g 15" v-model="properties.dia" type="number" min="1" max="31" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="d-flex align-items-center">
            <b-form-checkbox v-model="properties.done">Marcar como Done</b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary-dark" class="float-right" @click="submitLembrete">Salvar Lembrete</b-button>
        </div>
      </template>
    </b-modal>
  </template>
  
  <script>
  import * as LembreteService from "@/services/LembreteService";
  
  export default {
    data: () => ({
      properties: {
        _id: null,
        nome: "",
        dia: null,
        done: false,
        anoReferencia: new Date().getFullYear(),
        mesReferencia: null
      }
    }),
    methods: {
      submitLembrete() {
        const {
          _id,
          nome,
          anoReferencia,
          mesReferencia,
          dia,
          done,
        } = this.properties;
  
        if (nome == "") {
            this.$swal("Erro", "Insira o nome do lembrete", "error");
        } else if (dia > 31 || dia < 1 || !(/^\d+$/.test(dia))){
            this.$swal("Erro", "Insira um dia válido", "error");
        } else {
            LembreteService.editLembrete(this.properties._id,{
                _id,
                nome,
                done,
                dia,
                mesReferencia: `${mesReferencia
                .toString()
                .padStart(2, "0")}`
            })
            .then(() => {
              this.$swal("Sucesso", "O lembrete foi salvo", "success");
              this.$emit("onsave");
              this.$refs.modalEditarLembrete.hide();
            })
            .catch(() => {
              this.$swal(
                "Erro",
                "Ocorreu um erro ao salvar o lembrete, fale com o administrador do sistema",
                "error"
              );
            });
        }
      },
      showEdit(lembrete) {
        this.$refs.modalEditarLembrete.show();
        this.properties = { ...lembrete };
      }
    }
  };
  </script>
  
  <style lang="scss">
  .form-label {
    font-weight: bold;
    color: $primary-dark;
  }
  </style>