<template>
  <div>
    <div
      v-for="(listaAluno, i) in searchAlunosPendentes"
      :key="i"
      v-show="listaAluno.lista.length > 0"
    >
      <hr class="mt-5" />
      <h4 class="font-destaque dia-pendente">Dia {{listaAluno.dia}}</h4>
      <table class="table table-bordered tbl-pendentes">
        <thead>
          <tr>
            <th>Aluno</th>
            <th>CPF</th>
            <th width="400">Pendências</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(alunoPendente, index) in listaAluno.lista"
            :key="index"
            :class="{ 'table-danger': alunoPendente.atrasado }"
          >
            <td>{{alunoPendente.aluno.nome}}</td>
            <td>{{alunoPendente.aluno.cpf}}</td>
            <td>
              <a
                v-if="alunoPendente.pendencias.length > 1"
                block
                @click.prevent="opennedPendencia == index ? opennedPendencia = null : opennedPendencia = index"
                class="mb-2 text-info"
                :class="{ 'text-danger': alunoPendente.atrasado }"
                href="#"
              >Ver pendências</a>
              <b-collapse :visible="alunoPendente.pendencias.length > 1 ? opennedPendencia == index : true">
                <div
                  v-for="(pendencia, indexPendencia) in alunoPendente.pendencias"
                  :key="indexPendencia"
                  class="mb-1 card-pendencia d-flex"
                >
                  <span class="mr-4"><b>Data:</b> {{new Date(pendencia.data).toLocaleDateString()}}</span>
                  <span><b>Valor:</b> R$ {{pendencia.valor.toFixed(2)}}</span>
                </div>
              </b-collapse>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-alert
      variant="secondary"
      :show="loading"
      class="text-center m-2 mt-5"
    >
      <font-awesome-icon
        :icon="['fas', 'spinner']"
        spin
      />
    </b-alert>

    <b-alert
      :show="!loading && searchAlunosPendentes.length == 0"
      variant="dark"
      class="text-center"
    >
      Nenhuma pendencia encontrada
    </b-alert>
  </div>
</template>

<script>
import * as PagamentoService from "@/services/PagamentoService";

export default {
  props: ["search"],
  data: () => ({
    loading: true,
    pendentes: [],
    opennedPendencia: null
  }),
  computed: {
    searchAlunosPendentes() {
      const pendentes = JSON.parse(JSON.stringify(this.pendentes));

      pendentes.forEach(x => {
        x.lista = x.lista.filter(({ aluno }) => {
          let searchString = this.search.trim().toLowerCase();
          const nomeAluno = aluno.nome.toLowerCase();
          if (aluno.cpf) {
            const numerosCPF = aluno.cpf
              .split(".")
              .join("")
              .replace("-", "");
            searchString = searchString.split(".").join("");
            return (
              nomeAluno.includes(searchString) ||
              numerosCPF.includes(searchString)
            );
          }
          return nomeAluno.includes(searchString);
        });
      });

      return pendentes;
    }
  },
  mounted() {
    PagamentoService.fetchPendentes().then(({ data }) => {
      this.loading = false;

      // data.forEach(aluno => {
      //   const atrasado = aluno.pendencias.some(
      //     p =>
      //       new Date(Date.now()).toLocaleDateString() >
      //       new Date(p.data).toLocaleDateString()
      //   );
      //   aluno.atrasado = atrasado;
      // });

      this.pendentes = data;
    });
  }
};
</script>

<style lang="scss" scoped>
.card-pendencia {
  padding: 0 !important;
}

.tbl-pendentes {
  td {
    padding: 0.6em;
  }
}

.dia-pendente {
  color: $primary-dark;
}
</style>