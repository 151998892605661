var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow p-4 m-3 bg-white mapa-pagamentos-dia"},[_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('td',{staticClass:"text-center font-weight-bold color-primary-dark",attrs:{"colspan":"2"}},[_vm._v(" Dia "+_vm._s(_vm.dia)+" ")])])]),_c('tbody',_vm._l((_vm.alunos),function(aluno,i){return _c('tr',{key:i,class:{
          'text-success': aluno.pago,
          pago: aluno.pago,
          'text-info': _vm.pagamentoMaior(aluno),
          'pago-divergente-maior': _vm.pagamentoMaior(aluno),

          'text-warning': _vm.pagamentoMenor(aluno),
          'pago-divergente-menor': _vm.pagamentoMenor(aluno),

          'text-danger': aluno.possuiAtraso && !_vm.proximoMes,
          atrasado: aluno.possuiAtraso && !_vm.proximoMes,
        },on:{"click":function($event){return _vm.handleAlunoClick(aluno)}}},[_c('td',[_vm._v(" "+_vm._s(aluno.nome)+" "),(aluno.observacoesMes && aluno.observacoesMes.length)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'info-circle']}}):_vm._e()],1),(aluno.pago)?_c('td',{staticClass:"text-right"},[_vm._v(" PAGO "),_c('span',{staticClass:"valor-pago"},[_vm._v("(R$ "+_vm._s(_vm._f("currency")(aluno.pagamentoMes))+")")])]):_c('td',{staticClass:"text-right"},[_vm._v(" R$ "+_vm._s(_vm._f("currency")(aluno.pendencias.valor))+" ")])])}),0)]),_c('hr',{staticClass:"mx-auto mb-3",staticStyle:{"width":"50%"}}),_c('informe-total',{attrs:{"label":"Valor Esperado","valor":_vm.valorEsperado}}),_c('hr',{staticClass:"m-2"}),_c('informe-total',{attrs:{"label":"Valor Recebido","valor":_vm.valorRecebido}}),_c('hr',{staticClass:"m-2"}),_c('informe-total',{attrs:{"label":"Valor Restante","valor":_vm.valorRestante}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }