<template>
  <b-modal
    id="modal-pagamento"
    title="Informar Pagamento"
    size="lg"
    ok-only
    @ok.prevent="submitPagamento"
    :ok-disabled="loadingPagamento"
    @show="getDadosAluno"
    ref="modalPagamento"
  >
    <b-form>
      <b-form-group label="Aluno">
        <b-form-input :value="nomeAluno" disabled />
      </b-form-group>
      <b-form-group label="Descrição">
        <b-form-textarea
          id="textarea"
          placeholder="Descrição do pagamento..."
          rows="2"
          max-rows="6"
          v-model="descricao"
        />
      </b-form-group>
      <b-row>
        <b-col md="4">
          <b-form-group label="Mês de Referência">
            <b-form-select v-model="mesReferencia">
              <option :value="''" disabled>
                Selecione o mês de referencia
              </option>
              <option :value="1">Janeiro</option>
              <option :value="2">Fevereiro</option>
              <option :value="3">Março</option>
              <option :value="4">Abril</option>
              <option :value="5">Maio</option>
              <option :value="6">Junho</option>
              <option :value="7">Julho</option>
              <option :value="8">Agosto</option>
              <option :value="9">Setembro</option>
              <option :value="10">Outubro</option>
              <option :value="11">Novembro</option>
              <option :value="12">Dezembro</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Ano de Referência">
            <b-form-input
              type="number"
              v-model="anoReferencia"
              @blur="
                anoReferencia < 1920
                  ? (anoReferencia = new Date(Date.now()).getFullYear())
                  : true
              "
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Valor">
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"> R$ </span>
              </b-input-group-prepend>
              <b-form-input placeholder="0,00" v-model="valor" type="number" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Data do Pagamento">
            <date-picker
              format="DD/MM/YYYY"
              valueType="date"
              placeholder="dd/mm/aaaa"
              v-model="dataPagamento"
              class="w-100"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Observações">
            <b-form-textarea
              id="textarea"
              placeholder="Observações do pagamento..."
              rows="2"
              max-rows="6"
              v-model="observacao"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import * as AlunoService from "@/services/AlunoService";
import * as PagamentoService from "@/services/PagamentoService";

export default {
  props: ["aluno"],
  components: {
    DatePicker,
  },
  data: () => ({
    anoReferencia: new Date(Date.now()).getFullYear(),
    mesReferencia: new Date(Date.now()).getMonth() + 1,
    valor: "",
    nomeAluno: "",
    descricao: "",
    observacao: "",
    dataPagamento: new Date(),
    loadingPagamento: false,
    pendencias: null,
    pendenciasAluno: null,
  }),
  methods: {
    getDadosAluno() {
      if (this.aluno) {
        this.valor = this.aluno.valor.toFixed(2);
        this.nomeAluno = this.aluno.nome;
        this.descricao = `Pagamento de ${this.nomeAluno}`;

        this.pendencias.forEach((element) => {
          element.lista.forEach((l) => {
            if (l.aluno._id === this.aluno._id)
              this.pendenciasAluno = l.pendencias;
          });
        });

        if (this.pendenciasAluno) {
          this.mesReferencia =
            new Date(this.pendenciasAluno[0].data).getMonth() + 1;

          this.anoReferencia = new Date(
            this.pendenciasAluno[0].data
          ).getFullYear();
        }
      }
    },
    refreshPendencias() {
      PagamentoService.fetchPendentes().then((res) => {
        this.pendencias = res.data;
      });
    },
    submitPagamento() {
      this.loadingPagamento = true;

      const {
        anoReferencia,
        mesReferencia,
        dataPagamento,
        valor,
        descricao,
        observacao,
      } = this;

      AlunoService.informarPagamento(this.aluno._id, {
        descricao,
        observacao,
        valor,
        dataPagamento: this.$moment(dataPagamento).format("YYYY-MM-DD"),
        mesReferencia: `${mesReferencia
          .toString()
          .padStart(2, "0")}/${anoReferencia}`,
      })
        .then(() => {
          this.$swal(
            "Pagamento",
            "Pagamento registrado com sucesso",
            "success"
          ).then(() => (this.descricao = ""));
          this.refreshPendencias();
          this.$refs.modalPagamento.hide();
        })
        .catch(() =>
          this.$swal(
            "Erro",
            "Ocorreu um erro, fale com o administrador",
            "error"
          )
        )
        .finally(() => (this.loadingPagamento = false));
    },
  },
  mounted() {
    PagamentoService.fetchPendentes().then((res) => {
      this.pendencias = res.data;
    });
  },
};
</script>

<style>
</style>