import api from ".";

export function fetchByType(type) {
  return api.get(`/expressao/${type}`);
}

export function add(dados) {
  return api.post("/expressao", dados);
}

export function update(id, dados) {
  return api.put(`/expressao/${id}`, dados);
}

export function remove(id) {
  return api.delete(`/expressao/${id}`);
}
