import api from ".";

export function add(dados) {
  return api.post("/expressaoSchema", dados);
}

export function get() {
  return api.get("/expressaoSchema/");
}

export function remove(id) {
  return api.delete(`/expressaoSchema/${id}`);
}
