import router from "@/router";

const perfilModule = {
    namespaced: true,
  
    state: {
        perfil: localStorage.getItem('perfil') || 'pj'
    },

    mutations: {
        changePerfil(state){
            state.perfil = state.perfil === 'pj' ? 'pf' : 'pj'
            localStorage.setItem('perfil', state.perfil)
            router.go()
        }
    }
};

export default perfilModule;