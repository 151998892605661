<template>
    <div>
        <b-col md="12" v-for="alerta in alertas" :key="alerta.nome">
            <b-alert variant="danger" :show="alerta.dia >= 2">
                {{ tipo }} {{ alerta.nome }} vence em {{ alerta.dia }} dias
            </b-alert>
            <b-alert variant="danger" :show="alerta.dia == 1">
                {{ tipo }} {{ alerta.nome }} vence em {{ alerta.dia }} dia
            </b-alert>
            <b-alert variant="danger" :show="alerta.dia == 0">
                {{ tipo }} {{ alerta.nome }} vence hoje
            </b-alert>
            <b-alert variant="danger" :show="alerta.dia == -1">
                {{ tipo }} {{ alerta.nome }} venceu há {{ Math.abs(alerta.dia) }} dia
            </b-alert>
            <b-alert variant="danger" :show="alerta.dia <= -2">
                {{ tipo }} {{ alerta.nome }} venceu há {{ Math.abs(alerta.dia) }} dias
            </b-alert>
        </b-col>
    </div>
  </template>
  
  <script>

  
  export default {
    props: ["alertas", "tipo"],
    components: {
     }
  };
  </script>
  
  <style scoped lang="scss">
  
  </style>
  