<template>
  <b-modal
    id="modal-despesas-fixas"
    title="Despesas Fixas"
    size="lg"
    ok-only
    ref="modalDespesasFixas"
  >
    <b-form>
      <b-row>
        <b-col md="2">
          <b-form-group label="Dia">
            <b-form-input
              placeholder="e.g 15"
              v-model="dia"
              type="number"
              min="1"
              max="31"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Nome da Despesa">
            <b-form-input
              placeholder="Digite o nome da Despesa"
              v-model="nome"
              ref="nomeDespesa"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Valor">
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text">
                  R$
                </span>
              </b-input-group-prepend>
              <b-form-input
                placeholder="0,00"
                v-model="valorPadrao"
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="text-right pr-3">
        <b-button
          @click="salvarDespesa"
          :variant=" !editingID ? 'secondary' : 'info'"
        >
          {{ !editingID ? "Salvar Despesa" : "Editar Despesa" }}
        </b-button>
      </div>
    </b-form>

    <tabela-despesas-fixas
      :despesas="despesas"
      @onDeleteDespesa="fetchDespesas"
      @editDespesa="setEditMode"
    />

  </b-modal>
</template>

<script>
import * as DespesaService from "@/services/DespesaService";

import TabelaDespesasFixas from "./ModalDespesasFixas/TabelaDespesasFixas";

export default {
  components: {
    TabelaDespesasFixas,
  },
  data: () => ({
    editingID: "",
    nome: "",
    dia: 1,
    valorPadrao: "",
    despesas: [],
  }),
  mounted() {
    this.fetchDespesas();
  },
  methods: {
    fetchDespesas() {
      DespesaService.fetchFixas()
        .then((res) => {
          this.despesas = res.data;
        })
        .catch(() => {
          this.$swal(
            "Erro",
            "Ocorreu um erro ao carregar as despesas fixas, fale com o administrador do sistema"
          );
        });
    },
    salvarDespesa() {
      const { nome, valorPadrao, dia, editingID } = this;

      if (!nome) {
        this.$swal("Erro", "Digite o nome da despesa", "error");
      } else if (!valorPadrao) {
        this.$swal("Erro", "Digite o valor da despesa", "error");
      } else if (!dia) {
        this.$swal("Erro", "Digite o dia da despesa", "error");
      } else {
        var reqPromise;

        if (!editingID) {
          // Nova Despesa
          reqPromise = DespesaService.addFixa({ nome, valorPadrao, dia });
        } else {
          // Edita a despesa
          reqPromise = DespesaService.editFixa(editingID, {
            nome,
            valorPadrao,
            dia,
          });
        }

        reqPromise
          .then(() => {
            this.fetchDespesas();

            this.editingID = "";
            this.nome = "";
            this.valorPadrao = (0).toFixed(2);
          })
          .catch(() => {
            this.$swal(
              "Erro",
              "Ocorreu um erro ao salvar a despesa, fale com o administrador",
              "error"
            );
          });
      }
    },
    setEditMode(despesa) {
      this.editingID = despesa._id;
      this.nome = despesa.nome;
      this.valorPadrao = despesa.valorPadrao.toFixed(2);
      this.$refs.nomeDespesa.focus();
    },
  },
};
</script>

<style>
</style>