<template>
  <b-row>
    <b-col md="6">
      <b-form-group>
        <b-form-select v-model="selectedDate.mesReferencia">
          <option
            :value="''"
            disabled
          >Selecione o mês de referencia</option>
          <option :value="1">Janeiro</option>
          <option :value="2">Fevereiro</option>
          <option :value="3">Março</option>
          <option :value="4">Abril</option>
          <option :value="5">Maio</option>
          <option :value="6">Junho</option>
          <option :value="7">Julho</option>
          <option :value="8">Agosto</option>
          <option :value="9">Setembro</option>
          <option :value="10">Outubro</option>
          <option :value="11">Novembro</option>
          <option :value="12">Dezembro</option>
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group>
        <b-form-input
          type="number"
          v-model="selectedDate.anoReferencia"
          @blur="selectedDate.anoReferencia < 1920 ? selectedDate.anoReferencia = new Date().getFullYear() : true"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ["selectedDate"],
};
</script>

<style>
</style>