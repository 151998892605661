<template>
  <div>

    <dashboard-aluno 
      v-if="isAdmin === false"
    />

    <dashboard-admin
      v-if="isAdmin === true"
    />

  </div>
</template>

<script>

import DashboardAluno from '@/components/dashboard/DashboardAluno'
import DashboardAdmin from '@/components/dashboard/DashboardAdmin'

export default {
  components: {
    DashboardAluno,
    DashboardAdmin,
  },
  computed: {
    isAdmin(){
      const user = this.$store.state.account.user

      if(!user.token){
        return null
      } else if (user.admin){
        return true 
      }

      return false

    }
  }
}
</script>

<style>

</style>