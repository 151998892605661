import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import account from './modules/account'
import caixas from './modules/caixas'
import alunos from './modules/alunos'
import perfil from './modules/perfil'

export default new Vuex.Store({
  modules: { 
    account,
    caixas,
    alunos,
    perfil
  }
})
