<template>
  <div>

    <b-container
      class="mb-5"
      fluid
    >
      <b-row>
        <pessoal
          class="mt-5"
          :dados.sync="dadosUsuario"
          :validFields="validFields"
        />
        <endereco
          class="mt-5"
          :dados.sync="dadosUsuario"
          :validFields="validFields"
        />
        <curso
          class="mt-3"
          :dados.sync="dadosUsuario"
          :validFields="validFields"
          @generateAuth="generateAuth"
          :isEditing="isEditing"
        />

      </b-row>

      <b-row>
        <observacoes
          class="mt-3"
          :dados.sync="dadosUsuario"
        />

      </b-row>
      <b-btn
        variant="primary-dark"
        class="btn-salvar"
        @click="salvarAluno"
        :disabled="loading"
      >
        <font-awesome-icon
          :icon="['fas', 'spinner']"
          spin
          v-if="loading"
        />
        <span v-else>Salvar Aluno</span>
      </b-btn>

    </b-container>

  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Pessoal from "@/components/cadastroAluno/Pessoal";
import Endereco from "@/components/cadastroAluno/Endereco";
import Curso from "@/components/cadastroAluno/Curso";
import Observacoes from "@/components/cadastroAluno/Observacoes";

import * as AlunoAPI from "@/services/AlunoService";

export default {
  data: () => ({
    dadosUsuario: {
      // Pessoais
      nome: "",
      rg: "",
      cpf: "",
      dtNascimento: "",
      email: "",
      telefone: "",
      celular: "",

      // Endereço
      cep: "",
      logradouro: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",

      // Curso
      dtInicio: "",
      dtFim: "",
      nivel: "",
      caixa: null,
      valor: "",
      diaPagamento: "",

      //Observacoes
      Observacoes: "",

      // Auth
      auth: {
        username: "",
        password: "",
      },
    },
    validFields: {},
    loading: false,
    isEditing: false,
  }),
  components: {
    Pessoal,
    Endereco,
    Curso,
    Navbar,
    Observacoes,
  },
  beforeRouteUpdate(to, from, next) {
    this.$swal(
      "Sair",
      "Tem certeza que deseja sair? Dados poderão ser perdidos",
      "warning"
    );
  },
  async mounted() {
    // Verifica se o usuário está sendo editado
    if (this.$route.params.id) {
      const dadosAluno = this.$store.state.alunos.editing;

      if (!dadosAluno) {
        await this.$store.dispatch("alunos/fetchEditing", {
          id: this.$route.params.id,
        });
      }

      this.isEditing = true;
      this.dadosUsuario = this.$store.state.alunos.editing;
    } else {
      this.isEditing = false;
    }
  },
  methods: {
    validateForm() {
      const {
        auth: { username, password },
        nome,
        cpf,
        dtNascimento,
        dtInicio,
        dtFim,
      } = this.dadosUsuario;

      if (nome.length < 6) {
        this.$swal("Erro", "Insira o Nome do aluno", "error");
      } else if (cpf.length != 14) {
        this.$swal("Erro", "Preencha o CPF", "error");
      } else if (!dtNascimento) {
        this.$swal("Erro", "Selecione a Data de Nascimento", "error");
      } else if (!dtInicio || !dtFim) {
        this.$swal(
          "Erro",
          "Preencha a Data de Inicio e Data de Fim do Curso",
          "error"
        );
      } else if (dtInicio > dtFim) {
        this.$swal(
          "Erro",
          "A Data de Inicio do curso deve ser anterior à Data de Fim do curso",
          "error"
        );
      } else if (!username || !password) {
        this.$swal("Erro", "Preencha o Login e a Senha", "error");
      } else {
        return true;
      }

      return false;
    },
    salvarAluno() {
      if (!this.validateForm()) return;

      this.loading = true;

      if (this.isEditing) {
        // Se estiver editando
        this.$store
          .dispatch("alunos/edit", {
            _id: this.$route.params.id,
            ...this.dadosUsuario,
          })
          .then(() => {
            this.$swal("Sucesso", "O aluno foi salvo", "success");
          })
          .catch(() =>
            this.$swal(
              "Erro",
              "Ocorreu um erro, fale com o administrador",
              "error"
            )
          )
          .finally(() => (this.loading = false));
      } else {
        // Se estiver salvando
        this.$store
          .dispatch("alunos/add", this.dadosUsuario)
          .then(() => {
            this.$swal("Sucesso", "O aluno foi adicionado", "success");
            this.$router.push("/alunos");
          })
          .catch(() =>
            this.$swal(
              "Erro",
              "Ocorreu um erro, fale com o administrador",
              "error"
            )
          )
          .finally(() => (this.loading = false));
      }
    },
    async generateAuth() {
      if (this.dadosUsuario.nome) {
        const {
          data: { username, password },
        } = await AlunoAPI.generateAuth(this.dadosUsuario.nome.toLowerCase());

        this.dadosUsuario.auth = {
          username,
          password,
        };
      }
    },
  },
};
</script>

<style scoped>
.btn-salvar {
  width: 200px !important;
  padding: 10px;
  float: right;
  margin: 20px 0;
}
</style>