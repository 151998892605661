<template>
  <div class="table-responsive p-1">
    <table class="table table-bordered">
      <thead>
        <tr class="text-center">
          <th style="width: 10px">*</th>
          <th>Segunda</th>
          <th>Terça</th>
          <th>Quarta</th>
          <th>Quinta</th>
          <th>Sexta</th>
          <th>Sábado</th>
          <th>Domingo</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(agendamento, index) in agendamentos"
          :key="index"
        >
          <th class="text-center">{{agendamento.horario}}</th>

          <td
            v-for="agendaDia in agendamento.dias"
            :key="agendaDia.dia"
          >
            <!-- {{ getAgendaSemana(agendamento.dias, 'Segunda') }} -->
            <b-card
              v-for="aluno in agendaDia.alunos"
              :key="aluno.idAgendamento"
              :class=" { 'mb-1': true, 'remoto': aluno.modalidade == 'REMOTO' } "
              @mouseenter="showActions = aluno.idAgendamento"
              @mouseleave="showActions = null"
            >
              <div class="justify-content-between align-items-center">
                <small>
                  <b class="mr-2">{{aluno.nome}}</b>
                  <font-awesome-icon
                    :icon="['fas', 'laptop']"
                    v-if="aluno.modalidade == 'REMOTO'"
                  />
                </small>
                <div class="d-flex align-items-center ">
                  <small>{{getHorario(aluno)}}</small>
                  <small class="ml-3">
                    <b-link
                      href="#"
                      class="card-link text-danger float-right"
                      style="height: 20px"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'pencil-alt']"
                        v-show="showActions == aluno.idAgendamento"
                        @click="$emit('edit', aluno)"
                      />
                    </b-link>
                  </small>
                  <small class="ml-3">
                    <b-link
                      href="#"
                      class="card-link text-danger float-right"
                      style="height: 20px"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'trash']"
                        v-show="showActions == aluno.idAgendamento"
                        @click="deleteAgendamento(aluno.idAgendamento)"
                      />
                    </b-link>
                  </small>
                </div>
              </div>
            </b-card>
          </td>
        </tr>
      </tbody>
    </table>

    <b-alert
      variant="secondary"
      :show="loading"
      class="text-center m-2"
    >
      <font-awesome-icon
        :icon="['fas', 'spinner']"
        spin
      />
    </b-alert>

    <b-alert
      variant="secondary"
      class="text-center"
      :show="!loading && agendamentos.length == 0"
    >Nenhum agendamento encontrado</b-alert>
  </div>
</template>

<script>
import * as AgendamentoService from "@/services/AgendamentoService";
import * as DateUtils from "@/utils/dateUtils";

export default {
  data: () => ({
    agendamentos: [],
    loading: true,
    showActions: null,
  }),
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const response = await AgendamentoService.fetchAll();
      this.agendamentos = response.data;
      this.loading = false;
    },
    getHorario(aluno) {
      const { horaInicio, horaFim } = aluno;

      return `${DateUtils.formatHour(horaInicio)} - ${DateUtils.formatHour(
        horaFim
      )}`;
    },
    deleteAgendamento(idAgendamento) {
      this.$swal({
        title: "Apagar Agendamento",
        text: `Deseja realmente apagar esse agendamento?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
      }).then(({ value: remover }) => {
        if (remover) {
          AgendamentoService.remove(idAgendamento).then(() => this.fetch());
        }
      });
    },
  },
};
</script>

<style scoped>
.remoto {
  background: rgb(224, 237, 255);
}
</style>