<template>
  <div class="text-primary d-flex justify-content-between mx-1">
    <div>{{label}}:</div>
    <div>R$ {{valor | currency}}</div>
  </div>
</template>

<script>
export default {
    props: ['label', 'valor']
}
</script>