<template>
  <b-modal id="modal-despesas" title="Despesas" size="lg" ref="modalDespesa">
    <b-form>
      <b-row>
        <b-col md="8">
          <b-form-group label="Nome da Despesa" class="form-label">
            <b-form-input type="text" v-model="properties.nome" placeholder="Digite o nome da despesa" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Valor" class="form-label">
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text">R$</span>
              </b-input-group-prepend>
              <b-form-input placeholder="0,00" v-model="properties.valor" type="number" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <b-form-group label="Caixa" class="form-label">
            <b-form-select v-model="properties.caixa" class="mr-2">
              <option
                v-for="caixa in caixas.caixas.filter(caixa => caixa.perfil == this.$store.state.perfil.perfil)"
                :value="caixa.id"
                :key="caixa.id"
              >{{caixa.nome}}</option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Dia" class="form-label">
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text">Dia</span>
              </b-input-group-prepend>
              <b-form-input placeholder="e.g 15" v-model="properties.dia" type="number" min="1" max="31" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-items-center">
          <b-form-checkbox v-model="properties.pago">Marcar como Pago</b-form-checkbox>
        </b-col>
      </b-row>
    </b-form>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary-dark" class="float-right" @click="submitDespesa">Salvar Despesa</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import * as DespesaService from "@/services/DespesaService";
import { mapState } from "vuex";

export default {
  data: () => ({
    properties: {
      _id: null,
      nome: "",
      valor: "",
      dia: null,
      pago: false,
      caixa: null,
      anoReferencia: new Date().getFullYear(),
      mesReferencia: null
    }
  }),
  computed: {
    ...mapState(["caixas"])
  },
  methods: {
    submitDespesa() {
      const {
        _id,
        nome,
        anoReferencia,
        mesReferencia,
        dia,
        valor,
        pago,
        caixa
      } = this.properties;

      if (nome == "") {
        this.$swal("Erro", "Insira o nome da despesa", "error");
      } else if (!valor) {
        this.$swal("Erro", "Insira o valor da despesa", "error");
      } else if (pago && !caixa) {
        this.$swal("Erro", "Insira o caixa da despesa", "error");
      } else {
        DespesaService.editDespesa(this.properties._id,{
          _id,
          nome,
          valor,
          pago,
          caixa,
          dia,
          mesReferencia: `${mesReferencia
            .toString()
            .padStart(2, "0")}`
        })
          .then(() => {
            this.$swal("Sucesso", "A despesa foi salva", "success");
            this.$emit("onsave");
            this.$refs.modalDespesa.hide();
          })
          .catch(() => {
            this.$swal(
              "Erro",
              "Ocorreu um erro ao salvar a despesa, fale com o administrador do sistema",
              "error"
            );
          });
      }
    },
    showEdit(despesa) {
      this.$refs.modalDespesa.show();
      this.properties = { ...despesa };
    }
  },
  mounted() {
    this.caixa = this.caixas.caixas[0].id;
  }
};
</script>

<style lang="scss">
.form-label {
  font-weight: bold;
  color: $primary-dark;
}
</style>