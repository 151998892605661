<template>
  <b-col md="6">
    <b-card bg-variant="light">
        <h5>Dados Residenciais</h5>

        <hr>

        <b-form-group label="CEP:">
          <b-form-input 
            placeholder="000000-000"
            v-model="dados.cep"
            v-mask="'#####-###'"
          />
        </b-form-group>

        <b-row>

          <b-col md="8">
            <b-form-group label="Logradouro:">
              <b-form-input 
                placeholder="e.g Rua Orozimbo Maia"
                v-model="dados.logradouro"
                maxlength="150"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Nº:">
              <b-form-input 
                placeholder="0000"
                v-model="dados.numero"
                v-mask="'######'"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-form-group label="Bairro:">
          <b-form-input 
            placeholder="e.g Jardim das Flores"
            v-model="dados.bairro"
            maxlength="150"
          />
        </b-form-group>

        <b-form-group label="Cidade:">
          <b-form-input 
            placeholder="e.g Campinas"
            v-model="dados.cidade"
            maxlength="150"
          />
        </b-form-group>

        <b-form-group label="Estado:">
          <b-form-input 
            placeholder="e.g São Paulo"
            v-model="dados.estado"
            maxlength="150"
          />
        </b-form-group>
    </b-card>
  </b-col>
</template>

<script>

export default {
  props: ['dados', 'validFields'],
}

</script>

<style scoped>
.row-two-inside-form {
  width: 89%;
  margin-left: auto;
}
</style>
