<template>
  <b-container fluid class="mt-4">
    <div class="d-flex justify-content-center mb-3">
      <div class="mr-2">
        <b-btn class="d-block" href="#" variant="light" @click="voltarMes" v-if="proximoMes">
          <font-awesome-icon :icon="['fas','arrow-left']" /> Voltar Mês
        </b-btn>
      </div>
      <div class="mr-2">
        <b-btn class="d-block" href="#" variant="light" @click="avancarMes">
          <font-awesome-icon :icon="['fas','arrow-right']" /> Avançar Mês
        </b-btn>
      </div>
      <div class="mr-1">
        <b-btn
          class="d-block"
          href="#"
          variant="light"
          @click="visualizacaoPadrao = !visualizacaoPadrao"
        >
          <font-awesome-icon :icon="['fas','exchange-alt']" /> Trocar Visualização
        </b-btn>
      </div>
    </div>
    <div
      class="d-flex justify-content-center mb-3 font-weight-bold color-primary-dark"
      v-if="proximoMes"
    >Data de referência: {{ dataFormatada }}</div>

    <b-row v-if="visualizacaoPadrao">
      <mapa-pagamentos ref="mapaPagamentos" />
    </b-row>
    <b-row v-else>
      <b-col md="12">
        <b-col md="4" class="float-right mb-4">
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text">
                <font-awesome-icon :icon="['fas', 'search']" class="icon" />
              </span>
            </b-input-group-prepend>

            <b-form-input placeholder="Digite o Nome ou o CPF do Aluno" v-model="searchField" />
          </b-input-group>
        </b-col>

        <br />

        <tabela-pendentes :search="searchField" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MapaPagamentos from "@/components/pagamentos/MapaPagamentos";
import TabelaPendentes from "@/components/pagamentos/TabelaPendentes";

export default {
  data: () => ({
    searchField: "",
    visualizacaoPadrao: true,
    proximoMes: false,
    mesMostrado: new Date(),
    dataFormatada: ""
  }),
  components: {
    TabelaPendentes,
    MapaPagamentos
  },
  methods: {
    avancarMes() {
      this.mesMostrado.setMonth(this.mesMostrado.getMonth() + 1);
      this.proximoMes = true;
      this.$refs.mapaPagamentos.buscarPendentes(
        this.proximoMes,
        this.mesMostrado
      );
      this.formatarData();
    },
    voltarMes() {
      const today = new Date();
      this.mesMostrado.setMonth(this.mesMostrado.getMonth() - 1);
      if (
        today.getFullYear() === this.mesMostrado.getFullYear() &&
        today.getMonth() === this.mesMostrado.getMonth()
      ) {
        this.proximoMes = false;
        this.$refs.mapaPagamentos.buscarPendentes(false, null);
      } else {
        this.proximoMes = true;
        this.$refs.mapaPagamentos.buscarPendentes(
          this.proximoMes,
          this.mesMostrado
        );
      }
      this.formatarData();
    },
    formatarData() {
      this.dataFormatada = `${(this.mesMostrado.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${this.mesMostrado.getFullYear()}`;
    }
  }
};
</script>

<style>
</style>