<template>
  <div>

    <div
      class="header"
      style="height: 50vh"
    >

      <div class="header-content">

        <div style="visibility: hidden">Teacher Reh</div>

        <h4
          class="font-destaque text-center"
          style="margin-top: 5%; color: #FFF; font-size: 50px"
        >
          Teacher Reh
        </h4>
      </div>

    </div>

    <b-container
      class="p-2"
      fluid
    >
      <b-form @submit.prevent="login">
        <b-col
          class="box p-5"
          md="5"
          sm=""
        >
          <span
            class="font-destaque color-primary-dark text-center"
            style="font-size: 18px"
          >ACESSO AO SISTEMA</span>

          <hr>
          <b-alert
            variant="danger"
            :show="error"
          >{{error}}</b-alert>

          <b-input-group class="mt-4">
            <b-input-group-prepend>
              <div class="text-white bg-primary-dark flex-center login-before-input">
                <font-awesome-icon :icon="['fas', 'user']" />
              </div>
            </b-input-group-prepend>

            <b-input
              placeholder="Usuário"
              class="login-input"
              v-model="username"
            />
          </b-input-group>

          <b-input-group class="mt-3">
            <b-input-group-prepend>
              <div class="text-white bg-primary-dark flex-center login-before-input">
                <font-awesome-icon :icon="['fas', 'key']" />
              </div>

            </b-input-group-prepend>

            <b-input
              placeholder="Senha"
              type="password"
              class="login-input"
              v-model="password"
            />

          </b-input-group>

          <b-btn
            class="mt-4 px-5 font-destaque"
            variant="primary-dark"
            type="submit"
            :disabled="loading"
          >
            <font-awesome-icon
              :icon="['fas', 'spinner']"
              spin
              v-if="loading"
            />
            <span v-else>Entrar</span>
          </b-btn>

        </b-col>

      </b-form>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "home",
  data: () => ({
    username: "",
    password: "",
    error: null,
    loading: false
  }),
  methods: {
    login() {
      // Muda o estado
      const { username, password } = this;
      this.error = null;
      this.loading = true;

      // Chama a action de login
      this.$store
        .dispatch("account/login", { username, password })
        .then(res => {
          // Muda vai para a Dashboard
          this.$router.push("/");
        })
        .catch(err => {
          // Mostra a mensagem de erro
          this.error = err.response.data.mensagem;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  background: $primary-dark;
  background-image: url("https://www.transparenttextures.com/patterns/always-grey.png");
}

.header-content {
  background: rgba(209, 48, 44, 0.4);
  height: 100%;
}

.box {
  background: #fff;
  margin: auto;
  margin-top: -20vh;
  box-shadow: 7px 10px 35px #00000029;
  border-radius: 5px;
}

.login-input {
  border-left: none;
}

.login-before-input {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 50px;
}
</style>