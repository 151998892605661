<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    class="app-header mb-1"
  >
    <b-navbar-brand
      class="navbar-brand font-destaque"
      href="#"
      style="width: auto"
      to="/"
    >
      Teacher Reh
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse
      id="nav-collapse"
      is-nav
      class="pl-3"
    >
      <b-navbar-nav>
        <b-nav-item href="#">
          <router-link
            to="/"
            active-class="active"
            class="link"
            exact=""
          >
            <font-awesome-icon
              :icon="['fas', 'chart-bar']"
              class="icon"
            />
            Dashboard
          </router-link>
        </b-nav-item>

        <b-nav-item href="#" v-if="verificaPerfil('pj')">
          <router-link
            to="/alunos"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'users']"
              class="icon"
            />
            Alunos
          </router-link>
        </b-nav-item>

        <b-nav-item href="#" v-if="verificaPerfil('pj')">
          <router-link
            to="/agendamento"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'calendar-week']"
              class="icon"
            />
            Agendamento
          </router-link>
        </b-nav-item>

        <b-nav-item href="#" v-if="verificaPerfil('pj')">
          <router-link
            to="/pagamentos"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'dollar-sign']"
              class="icon"
            />
            Pagamentos
          </router-link>
        </b-nav-item>

        <b-nav-item href="#">
          <router-link
            to="/renda"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'coins']"
              class="icon"
            />
            Renda
          </router-link>
        </b-nav-item>

        <b-nav-item href="#">
          <router-link
            to="/caixas"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'wallet']"
              class="icon"
            />
            Caixas
          </router-link>
        </b-nav-item>

        <b-nav-item href="#">
          <router-link
            to="/despesas"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'hand-holding-usd']"
              class="icon"
            />
            Despesas
          </router-link>
        </b-nav-item>

        <b-nav-item href="#" v-if="verificaPerfil('pj')">
          <router-link
            to="/expressoes"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'comments']"
              class="icon"
            />
            Expressões
          </router-link>
        </b-nav-item>

        <b-nav-item href="#" v-if="verificaPerfil('pj')">
          <router-link
            to="/agenda"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'calendar-alt']"
              class="icon"
            />
            Agenda
          </router-link>
        </b-nav-item>

        <b-nav-item href="#" v-if="verificaPerfil('pj')">
          <router-link
            to="/relatorio"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'paperclip']"
              class="icon"
            />
            Relatório
          </router-link>
        </b-nav-item>
        <b-nav-item href="#">
          <router-link
            to="/sistemas"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'key']"
              class="icon"
            />
            Senhas
          </router-link>
        </b-nav-item>

        <b-nav-item href="#" v-if="verificaPerfil('pj')">
          <router-link
            to="/lembretes"
            active-class="active"
            class="link"
          >
            <font-awesome-icon
              :icon="['fas', 'calendar-check']"
              class="icon"
            />
            Lembretes
          </router-link>
        </b-nav-item>

      </b-navbar-nav>

      <b-navbar-nav class="ml-auto mr-3">
        <b-nav-item-dropdown
          right
          :text="textoDropDown()"
        >
          <b-dropdown-item @click="loggoutAccount()">Sair</b-dropdown-item>
          <b-dropdown-item @click="changePerfil"> 
            {{ this.$store.state.perfil.perfil === 'pj' ? 'Pessoa Física' : 'Pessoa Jurídica' }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  methods: {
    loggoutAccount() {
      this.$store.commit("account/loggout");
      this.$router.push("/login");
    },
    changePerfil() {
      this.$store.commit("perfil/changePerfil")
    },
    verificaPerfil(perfil){
      return this.$store.state.perfil.perfil == perfil
    },
    textoDropDown(){
      if (this.$store.state.account.user.admin === false ) 
        return "Usuário"
      
      return this.$store.state.perfil.perfil === 'pj' ? 'Pessoa Jurídica' : 'Pessoa Física'
    }
  },
};
</script>

<style lang="scss" scoped>
.app-header,
.app-header > .navbar-content {
  /* height: 70px; */
  padding: 10px 0;
  margin: 0;
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, .2) */
}
.app-header  ul {
    display: flex;
    flex-wrap: wrap;
}

.app-header {
  background-color: $primary-dark;
  color: #fff !important;
}

.link {
  color: rgba(238, 238, 238, 0.6);
  text-decoration: none !important;
  background: rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &:hover {
    opacity: 0.5;
  }
}

@media screen and (max-width: 991px) {
  .link {
    background: none;
    opacity: 1 !important;
  }
}

.active {
  color: #fff !important;
  opacity: 1 !important;
}

.navbar-brand {
  width: 220px;
  height: 50px;
  padding: 0.5rem 1rem;
  color: #ffff !important;
}

.icon {
  margin-right: 5px;
}
</style>
