<template>
  <b-modal
    id="modal-alunos-proximo-mes"
    title="Alunos Ativos no Proximo Mês"
    size="lg"
    ok-only
  >

    <table class="table table-striped">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(aluno, i) in alunos"
          :key="i"
        >
          <td>{{aluno.nome}}</td>
          <td>R$ {{aluno.valor}}</td>
        </tr>
      </tbody>
    </table>

  </b-modal>
</template>

<script>
export default {
  props: ["alunos"]
};
</script>

<style>
</style>