<template>
  <b-form>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Nome da Despesa"
          class="form-label"
        >
          <b-form-input
            type="text"
            v-model="nome"
            placeholder="Digite o nome da despesa"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Valor"
          class="form-label"
        >
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text">R$</span>
            </b-input-group-prepend>
            <b-form-input
              placeholder="0,00"
              v-model="valor"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group
          label="Caixa"
          class="form-label"
        >
          <b-form-select
            v-model="caixa"
            class="mr-2"
          >
            <option
              v-for="caixa in caixas.caixas.filter(caixa => caixa.perfil == this.$store.state.perfil.perfil)"
              :value="caixa.id"
              :key="caixa.id"
            >{{caixa.nome}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          label="Dia"
          class="form-label"
        >
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text">Dia</span>
            </b-input-group-prepend>
            <b-form-input
              placeholder="e.g 15"
              v-model="dia"
              type="number"
              min="1"
              max="31"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-center">
        <b-form-checkbox v-model="pago">
          Marcar como Pago
        </b-form-checkbox>
      </b-col>

      <b-col>
        <b-button
          class="float-right"
          variant="primary-dark"
          @click="submitDespesa"
        >Salvar Despesa</b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import * as DespesaService from "@/services/DespesaService";
import { mapState } from "vuex";

export default {
  props: ["anoReferencia", "mesReferencia"],
  data: () => ({
    nome: "",
    valor: "",
    dia: new Date().getDate(),
    pago: false,
    caixa: null,
  }),
  computed: {
    ...mapState(["caixas"]),
  },
  methods: {
    submitDespesa() {
      const {
        nome,
        anoReferencia,
        mesReferencia,
        dia,
        valor,
        pago,
        caixa,
      } = this;

      const perfil = this.$store.state.perfil.perfil;

      if (nome == "") {
        this.$swal("Erro", "Insira o nome da despesa", "error");
      } else if (!valor) {
        this.$swal("Erro", "Insira o valor da despesa", "error");
      } else if (pago && !caixa) {
        this.$swal("Erro", "Insira o caixa da despesa", "error");
      } else {
        DespesaService.add({
          nome,
          valor,
          pago,
          caixa,
          dia,
          perfil,
          mesReferencia: `${mesReferencia
            .toString()
            .padStart(2, "0")}/${anoReferencia}`,
        })
          .then(() => {
            this.$swal("Sucesso", "A despesa foi salva", "success");
            this.nome = "";
            this.valor = "";
            this.pago = false;
            this.$emit("novaDespesa", { mesReferencia, anoReferencia });
          })
          .catch(() => {
            this.$swal(
              "Erro",
              "Ocorreu um erro ao salvar a despesa, fale com o administrador do sistema",
              "error"
            );
          });
      }
    },
  },
  mounted() {
    this.caixa = this.caixas.caixas.filter(caixa => caixa.perfil == this.$store.state.perfil.perfil)[0].id;
  },
};
</script>

<style lang="scss">
.form-label {
  font-weight: bold;
  color: $primary-dark;
}
</style>