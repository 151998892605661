<template>
  <div id="summary" style="max-width: 85%" class="m-auto">
    <b-card class="card-total m-auto mb-3">
      <b-row>
        <div class="card-body">
          <table class="table">
            <thead></thead>
            <tbody>
              <tr>
                <th class="no-border">
                  <span style="font-size: 24px; color: #82d382">Resumo</span>
                </th>
                <th
                  v-for="(monthData, index) in financialData"
                  :key="index"
                  style="font-size: 20px"
                  class="no-border"
                >
                  {{ monthData.month }}
                </th>
              </tr>
              <tr class="custom-border">
                <th>Renda</th>
                <td v-for="(monthData, index) in financialData" :key="index">
                  R$ {{ monthData.income | currency }}
                </td>
              </tr>
              <tr>
                <th class="no-border">Despesas</th>
                <td
                  v-for="(monthData, index) in financialData"
                  :key="index"
                  class="no-border"
                >
                  R$ {{ monthData.expense | currency }}
                </td>
              </tr>
              <tr class="custom-border">
                <th>Saldo Líquido do mês</th>
                <td
                  v-for="(monthData, index) in financialData"
                  :key="index"
                  style="font-weight: bold"
                >
                  R$ {{ monthData.netBalance | currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-row>
    </b-card>
  </div>
</template>
  
  <script>
import * as DespesaService from "@/services/DespesaService";
import * as RendaService from "@/services/RendaService";
export default {
  data() {
    return {
      financialData: [],
    };
  },
  async created() {
    await this.generateFinancialData();
  },
  methods: {
    async generateFinancialData() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];

      for (let month = 1; month <= currentMonth; month++) {
        let income;
        let expense;
        const monthString = month.toString().padStart(2, "0");
        await DespesaService.fetch(currentYear, monthString).then((res) => {
          expense = res.data.total;
        });
        await RendaService.fetch(currentYear, monthString).then((res) => {
          income = res.data.total;
        });
        this.financialData.push({
          month: `${monthNames[month - 1]}`,
          income: income,
          expense: expense,
          netBalance: income - expense,
        });
      }
    },
  },
};
</script>
  
<style>
.no-border {
  border: none !important;
}
.custom-border {
  border-top: 3px solid rgba(0, 0, 0, 0.4);
}
table {
  font-size: 18px;
}
</style>