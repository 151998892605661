<template>
  <div
    class="pb-4"
    v-if="!loading"
  >
    <navbar :nomeAluno="dashboardInfo.aluno.nome" />
    <b-container
      fluid
      class="mt-3"
    >
      <info-cards :infoAluno="dashboardInfo.aluno"></info-cards>

      <br />

      <b-row>
        <agenda-aluno :agendamento="dashboardInfo.agendamento" />

        <b-col md="6">
          <pagamentos-aluno :pendencias="dashboardInfo.pendencias" />
        </b-col>

      </b-row>

    </b-container>

    <modal-pagamentos-aluno :pendencias="dashboardInfo.pendencias" />

  </div>
  <div
    v-else
    class="text-center"
  >
    <font-awesome-icon
      :icon="['fas', 'spinner']"
      class="loading-spinner"
      spin
    />
  </div>
</template>

<script>
import Navbar from "./dashboardAluno/Navbar";
import InfoCards from "./dashboardAluno/InfoCards";
import AgendaAluno from "./dashboardAluno/AgendaAluno";
import PagamentosAluno from "./dashboardAluno/PagamentosAluno";
import ModalPagamentosAluno from "./dashboardAluno/ModalPagamentosAluno";

import * as DashboardAlunoService from "@/services/DashboardAlunoService";

export default {
  data: () => ({
    dashboardInfo: {
      aluno: {},
      agendamento: {},
      pendencias: {}
    },
    loading: true
  }),
  components: {
    Navbar,
    InfoCards,
    AgendaAluno,
    PagamentosAluno,
    ModalPagamentosAluno
  },
  mounted() {
    DashboardAlunoService.fetchDashboardAluno()
      .then(r => {
        this.dashboardInfo = r.data;
        this.loading = false;
      })
      .catch(err => {
        if (err.response.status != 401) {
          this.$swal(
            "Erro",
            "Ocorreu um erro, tente novamente mais tarde",
            "error"
          ).then(() => {
            this.$store.commit("account/loggout");
            this.$router.push("/login");
          });
        }
      });
  }
};
</script>

<style>
.loading-spinner {
  font-size: 40px;
  margin-top: 40px;
}
</style>