<template>
  <div>
    <b-container fluid class="mt-4">
      <b-card no-body>
        <b-tabs card>
          <div v-for="(expressao) in expressoes" :key="expressao._id">
            <b-tab>
              <template #title>
                <div class="row">
                  <span class="flex">{{expressao.nome}}</span>
                  <b-button class="button-delete-tab" @click="deletarAba(expressao)">
                    <font-awesome-icon :icon="['fas', 'times']" class="icon" />
                  </b-button>
                </div>
              </template>
              <expressoes :id="expressao._id" :titulo="expressao.nome" />
            </b-tab>
          </div>
          <template #tabs-end>
            <b-nav-item role="presentation" @click="novaAba" href="#">
              <font-awesome-icon :icon="['fas', 'plus']" class="icon" />
            </b-nav-item>
          </template>
        </b-tabs>
      </b-card>
    </b-container>
    <modal-nova-aba @update="fetchData()" />
  </div>
</template>

<script>
import Expressoes from "@/components/expressoes/Expressoes";
import * as ExpressaoSchemaService from "@/services/ExpressaoSchemaService";
import ModalNovaAba from "@/components/expressoes/ModalNovaAba.vue";

export default {
  components: {
    Expressoes,
    ModalNovaAba
  },
  methods: {
    novaAba() {
      this.$bvModal.show("modal-nova-aba");
    },
    deletarAba(expressao) {
      this.$swal({
        title: "Remover Aba",
        text: "Deseja realmente remover a aba " + expressao.nome + " ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim"
      }).then(({ value: remover }) => {
        if (remover) {
          ExpressaoSchemaService.remove(expressao._id)
            .then(() => {
              this.fetchData();
            })
            .catch(() => {
              this.$swal(
                "Erro",
                "Ocorreu um erro ao apagar a expressão, fale com o administrador do sistema",
                "error"
              );
            });
        }
      });
    },
    fetchData() {
      ExpressaoSchemaService.get().then(res => {
        this.expressoes = res.data;
      });
    }
  },
  data: () => ({
    expressoes: null
  }),
  mounted() {
    ExpressaoSchemaService.get().then(res => {
      this.expressoes = res.data;
    });
  }
};
</script>

<style lang="scss">
a[role="tab"] {
  color: $primary-dark !important;
}
</style>

<style lang="scss" scoped>
.nav-link {
  color: $primary-dark;
}

.button-delete-tab {
  margin-left: 15px;
  color: grey;
  background-color: transparent;
  border-radius: 50%;
  border: 1px;
  padding: 2px;
  width: 25px;
  height: 25px;
}

.button-delete-tab:hover {
  color: white;
  background-color:$primary-dark;
  border-radius: 50%;
}
</style>