<template>
  <div class="header-container">

    <b-row>
      <b-col md="3">
        <header-card title="Total de Alunos">
          <h4><b>{{dados.quantidadeAlunos}}</b></h4>
        </header-card>
      </b-col>

      <b-col md="3">
        <header-card title="Alunos Ativos no Mês">
          <h4><b>{{dados.quantidadeAlunosAtivosNoMes}}</b></h4>
        </header-card>
      </b-col>

      <b-col md="3">
        <header-card title="Alunos Particulares">
          <h4>
            <b>{{dados.quantidadeAlunosParticulares}}</b>
            <span style="font-size: 12px">&nbsp;(R$ {{dados.valorAlunosParticulares | currency}})</span>
          </h4>

        </header-card>
      </b-col>

      <b-col md="3">
        <header-card
          title="Aniversariantes do Dia"
          :class="{'contrast' : dados.aniversariantes.length > 0}"
        >
          <div
            class="mb-2 aniversariantes-container"
            v-if="dados.aniversariantes.length > 0"
          >
            <div
              :class="{ 'mt-2': dados.aniversariantes.length > 1 }"
              v-for="(anv, i) in dados.aniversariantes"
              :key="i"
            >
              <b>• {{anv.nome}}</b><br v-if="i < dados.aniversariantes.length - 1">
            </div>
          </div>
          <small
            v-else
            class="d-block"
          >Nenhum hoje!</small>
        </header-card>
      </b-col>
    </b-row>

    <b-row >
      <b-col md="3">
        <header-card title="Falta Receber">
          <h4><b>{{dados.restantePagamentos | currency}}</b></h4>
        </header-card>
      </b-col>

      <b-col md="3">
        <header-card title="Total Caixas">
          <h4><b>{{ dados.totalCaixas | currency }}</b></h4>
        </header-card>
      </b-col>

      <b-col md="3" >
        <router-link to="/despesas/false" class="link">
          <header-card title="Total Despesas Abertas">
          <h4><b>{{dados.totalDespesasAberto | currency}}</b></h4>
        </header-card>
        </router-link>
      </b-col>

      <b-col md="3">
        <router-link to="/despesas/true" class="link">
          <header-card title="Total Despesas Pagas">
          <h4><b>{{dados.totalDespesasPago | currency}}</b></h4>
          </header-card>
        </router-link>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <header-card title="Planejado Mês Atual">
          <h4><b>{{dados.totalPlanejadoAlunos | currency}}</b></h4>
        </header-card>
      </b-col>

      <b-col md="3"
        @click="$bvModal.show('modal-alunos-proximo-mes')"
        class="header-col-click"
      >
        <header-card title="Planejado Próximo Mês">
          <h4><b>{{dados.totalPlanejadoAlunosProximoMes | currency}}</b></h4>
        </header-card>
      </b-col>

      <b-col md="3">
        <header-card title="Diferença">
          <h4>
            <b>{{ getDiferenca() | currency }}</b>
          </h4>
        </header-card>
      </b-col>

      <b-col
        md="3"
        @click="$bvModal.show('modal-alunos-finalizando')"
        class="header-col-click"
      >
        <header-card title="Alunos Terminando o Curso">
          <h4><b>{{dados.alunosFinalizando.length}}</b></h4>
        </header-card>
      </b-col>
    </b-row>

    <modal-alunos-finalizando :alunos="dados.alunosFinalizando" />
    <modal-alunos-proximo-mes :alunos="dados.alunosAtivosNoProximoMes" />

    <b-row class="content-wrapper">
      <b-row class="dashboard-title">
        <b-col md="12">
          <h4> Pessoa Física </h4>
        </b-col>
      </b-row>

      <b-row class=" w-100">
        <b-col md="4">
          <header-card title="Total Caixas">
            <h4><b>{{ dadosPf.totalCaixas | currency  }}</b></h4>
          </header-card>
        </b-col>

        <b-col md="4" >
          <header-card title="Total Despesas Abertas">
            <h4><b>{{dadosPf.totalDespesasAberto | currency }}</b></h4>
          </header-card>
        </b-col>

        <b-col md="4">
          <header-card title="Total Despesas Pagas">
            <h4><b>{{dadosPf.totalDespesasPago | currency }}</b></h4>
          </header-card>
        </b-col>

      </b-row>
    </b-row>

  </div>
</template>

<script>
import HeaderCard from "./header/HeaderCard";
import ModalAlunosFinalizando from "./ModalAlunosFinalizando";
import ModalAlunosProximoMes from "./ModalAlunosProximoMes";

export default {
  props: ["dados", "dadosPf"],
  components: {
    HeaderCard,
    ModalAlunosFinalizando,
    ModalAlunosProximoMes
  },
  methods:{
    /*Renda + Planejado mes atual - Total Despesas Abertas PJ - 
    Total despesas pagas PJ - Total Despesas Abertas PF - 
    Total Despesas Pagas PF*/
    getDiferenca() {
      return (
        this.dadosPf.rendaExtra +
        this.dados.totalPlanejadoAlunos -
        this.dados.totalDespesasAberto -
        this.dados.totalDespesasPago -
        this.dadosPf.totalDespesasAberto -
        this.dadosPf.totalDespesasPago
      );
    },
  }
};
</script>

<style scoped lang="scss">
.header-container {
  width: 100%;
  min-height: 630px;
  background: $primary-dark;
  margin-top: -5px;
  position: relative;
}

.header-col-click {
  cursor: pointer;

  & > div {
    transition: all 0.2s;
    &:hover, &:active {
      background: #f0f0f0;
    }
  }
}

.card-container {
  justify-content: center;
  position: absolute;
  bottom: -70px;
}

.contrast {
  border: 4px solid #e4c979;
}

.aniversariantes-container {
  max-height: 70px;
  overflow: auto;
}

@media screen and (max-width: 991px) {
  .card-container {
    position: initial;
  }

  .header-container {
    margin-bottom: 0;
    padding-bottom: 10px;
  }
}

.content-wrapper {
  margin-top: 20px;
}

.link {
  color: #2C3E50;
  text-decoration: none;
}
.dashboard-title {
  color: white;
}
</style>
