<template>
  <b-col md="6">
    <b-card bg-variant="light">
        <h5>Dados Pessoais</h5>

        <small>( * ) Obrigatório</small>

        <hr>

        <b-form-group label="* Nome:">
          <b-form-input 
            placeholder="e.g Tiago Carvalho da Silva"
            v-model="dados.nome"
            maxlength="150"
            :state="dados.nome.length > 5"
          />
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group label="RG:">
              <b-form-input 
                placeholder="00.000.000-X"
                v-model="dados.rg"
                v-mask="'##.###.###-NN'"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="* CPF:">
              <b-form-input 
                placeholder="000.000.000-00"
                v-model="dados.cpf"
                v-mask="'###.###.###-##'"
                :state="dados.cpf.length == 14"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="* Data de Nascimento:">
          <date-picker 
            format="DD/MM/YYYY"
            valueType="date"
            placeholder="Selecione a data de nascimento"
            v-model="dados.dtNascimento"
            class="w-100"
            
          />
        </b-form-group>

        <b-form-group label="Email:">
          <b-form-input 
            placeholder="e.g email@prov.com"
            v-model="dados.email"
            maxlength="150"
          />
        </b-form-group>

        <b-row>

          <b-col>
            <b-form-group label="Telefone:">
              <b-form-input 
                placeholder="(00) 0000-0000"
                v-model="dados.telefone"
                v-mask="'(##) ####-####'"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Celular:">
              <b-form-input 
                placeholder="(00) 00000-0000"
                v-model="dados.celular"
                v-mask="'(##) # #### ####'"
              />
            </b-form-group>
          </b-col>

        </b-row>

    </b-card>
  </b-col>
</template>

<script>

import DatePicker from 'vue2-datepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

export default {
  props: ['dados', 'validFields', 'formValid'],
  components: {
    DatePicker
  },
}
</script>

<style scoped>
.row-two-inside-form {
  width: 89%;
  margin-left: auto;
}
</style>
