<template>
  <b-form>
    <b-row>
      <b-col md="12">
        <b-form-group label="Descrição">
          <b-form-input
            type="text"
            v-model="descricao"
            placeholder="Digite a descrição da renda"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group label="Mês de Referência">
          <b-form-select v-model="mesReferencia">
            <option
              :value="''"
              disabled
            >Selecione o mês de referencia</option>
            <option :value="1">Janeiro</option>
            <option :value="2">Fevereiro</option>
            <option :value="3">Março</option>
            <option :value="4">Abril</option>
            <option :value="5">Maio</option>
            <option :value="6">Junho</option>
            <option :value="7">Julho</option>
            <option :value="8">Agosto</option>
            <option :value="9">Setembro</option>
            <option :value="10">Outubro</option>
            <option :value="11">Novembro</option>
            <option :value="12">Dezembro</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Ano de Referência">
          <b-form-input
            type="number"
            v-model="anoReferencia"
            @blur="anoReferencia < 1920 ? anoReferencia = new Date().getFullYear() : true"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Valor">
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text">R$</span>
            </b-input-group-prepend>
            <b-form-input
              placeholder="0,00"
              v-model="valor"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Valor Restante">
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text">R$</span>
            </b-input-group-prepend>
            <b-form-input
              placeholder="0,00"
              v-model="valorRestante"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Caixa">
          <b-form-select
            v-model="caixa"
            class="mr-2"
          >
            <option
              v-for="caixa in caixas.caixas.filter(caixa => caixa.perfil == this.$store.state.perfil.perfil)"
              :value="caixa.id"
              :key="caixa.id"
            >{{caixa.nome}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
      class="float-right"
      @click="submit"
    >Salvar</b-button>
  </b-form>
</template>

<script>
import * as RendaService from "@/services/RendaService";
import { mapState } from "vuex";

export default {
  data: () => ({
    descricao: "",
    anoReferencia: new Date().getFullYear(),
    mesReferencia: new Date().getMonth() + 1,
    valor: "",
    valorRestante: "",
    caixa: null
  }),
  computed: {
    ...mapState(["caixas"])
  },
  methods: {
    submit() {
      const {
        descricao,
        anoReferencia,
        mesReferencia,
        valor,
        valorRestante,
        caixa
      } = this;

      const perfil = this.$store.state.perfil.perfil;

      if (descricao == "") {
        this.$swal("Erro", "Insira o descricao da renda", "error");
      } else if (!valor) {
        this.$swal("Erro", "Insira o valor da renda", "error");
      } else if (!caixa) {
        this.$swal("Erro", "Insira o caixa da renda", "error");
      } else {
        RendaService.add({
          descricao,
          valor,
          valorRestante,
          caixa,
          perfil,
          mesReferencia: `${mesReferencia
            .toString()
            .padStart(2, "0")}/${anoReferencia}`
        })
          .then(() => {
            this.$swal("Sucesso", "A renda foi salva", "success");
            this.descricao = "";
            this.valor = "";
            this.valorRestante = "";
            this.$emit("novaRenda", { mesReferencia, anoReferencia });
          })
          .catch(() => {
            this.$swal(
              "Erro",
              "Ocorreu um erro ao salvar a renda, fale com o administrador do sistema",
              "error"
            );
          });
      }
    }
  },
  mounted() {
    this.caixa = this.caixas.caixas.filter(caixa => caixa.perfil == this.$store.state.perfil.perfil)[0].id;
  }
};
</script>

<style>
</style>