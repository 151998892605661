<template>
  <b-modal
    id="modal-lista-pagamentos-dia"
    title="Pagamentos"
    size="lg"
    ok-only
  >

    <table class="table table-striped">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Pagamento</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(aluno, i) in alunos"
          :key="i"
        >
          <td>{{aluno.nome}}</td>
          <td v-if="aluno.pagamentoMes" class="text-success">Pago</td>
          <td v-else class="text-danger">Aguardando Pagamento</td>
        </tr>
      </tbody>
    </table>

  </b-modal>
</template>

<script>
export default {
  props: ["alunos"]
};
</script>

<style>
</style>