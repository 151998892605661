<template>
  <div class="main-gerenciador">
    <b-container fluid style="overflow: auto">
      <b-card>
        <h4 class="color-primary-dark text-center mt-4 mb-4">Gerenciador de senhas</h4>
        <table class="table table-striped tbl-sistemas">
          <thead>
            <tr>
              <td>Sistema</td>
              <td>Usuário</td>
              <td>Senha</td>
              <td class="text-center icon-column">Editar</td>
              <td class="text-center icon-column">Excluir</td>
            </tr>
          </thead>

          <tbody>
            <tr v-for="sistema in sistemas" :key="sistema._id">
              <td>{{sistema.sistema}}</td>
              <td>{{sistema.usuario}}</td>
              <td><password :senha="sistema.password"/></td>

              <td class="text-center icon-column">
                <b-button variant="light" @click="editSistema(sistema)">
                  <font-awesome-icon :icon="['fas', 'pencil-alt']" class="text-danger" />
                </b-button>
              </td>

              <td class="text-center icon-column">
                <b-button variant="light" @click="deleteSistema(sistema._id)">
                  <font-awesome-icon :icon="['fas', 'trash']" class="text-danger" />
                </b-button>
              </td>
            </tr>
            <tr v-if="sistemas.length == 0">
              <td>Nenhuma senha cadastrada.</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-container>

    <modal-senha ref="modalSenha" @update="fetchData" />
    <float-button label="Nova Senha" icon="plus" v-b-modal.modal-senha />
  </div>
</template>

<script>
import FloatButton from "@/components/FloatButton";
import * as SistemaService from "@/services/SistemaService";
import ModalSenha from "@/components/sistemas/Modal";
import Password from "@/components/sistemas/Password";
import store from "@/store";

export default {
  data: () => ({
    sistemas: []
  }),
  components: {
    FloatButton,
    ModalSenha,
    Password
  },
  methods: {
    fetchData() {
      SistemaService.get()
        .then(res => {
          const perfil = store.state.perfil.perfil
          let sistemas = res.data
          this.sistemas = sistemas.filter(sistema => sistema.perfil == perfil)
        })
        .catch(() =>
          this.$swal(
            "Erro",
            "Ocorreu um erro ao carregar as expressões, fale com o administrador do sistema",
            "error"
          )
        );
    },
    editSistema(expressao) {
      this.$refs.modalSenha.showEdit(expressao);
    },
    deleteSistema(id) {
      this.$swal({
        title: "Remover Senha",
        text: "Deseja realmente este registro?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim"
      }).then(({ value: remover }) => {
        if (remover) {
          SistemaService.remove(id)
            .then(() => {
              this.fetchData();
            })
            .catch(() => {
              this.$swal(
                "Erro",
                "Ocorreu um erro ao apagar a expressão, fale com o administrador do sistema",
                "error"
              );
            });
        }
      });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
.main-gerenciador {
  margin-top: 20px;
}
.tbl-sistemas thead {
  background: #7c7c7c;
  color: #fff;
  font-weight: bold;
}

.tbl-sistemas td {
  overflow-wrap: break-word;
}

.icon-column {
  width: 100px;
}
</style>