<template>
  <b-modal
    :id="'modal-expressao_'+id"
    :title="titulo"
    size="lg"
    hide-footer
    ref="modalExpressao"
    @hide="refresh()"
  >

    <b-form-group label="Expression">
      <b-form-input
        placeholder="Expression"
        v-model="expression"
      />
    </b-form-group>

    <b-form-group label="What's Mean">
      <b-form-input
        placeholder="What's Mean"
        v-model="whatsMean"
      />
    </b-form-group>

    <div class="text-right">
      <b-button
        @click="salvarDespesa"
        variant="primary-dark"
      >
        Salvar
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import * as ExpressaoService from "@/services/ExpressaoService";

export default {
  props:["id","titulo"],
  components: {},
  data: () => ({
    editingId: "",
    expression: "",
    whatsMean: "",
  }),
  methods: {
    salvarDespesa() {
      const { expression, whatsMean, editingId } = this;

      if (!expression) {
        this.$swal("Erro", "Preencha o campo 'Expression'", "error");
      } else if (!whatsMean) {
        this.$swal("Erro", "Preencha o campo 'What's Mean'", "error");
      } else {
        var reqPromise;

        if (!editingId) {
          // Nova Expressão
          reqPromise = ExpressaoService.add(this.getData());
        } else {
          // Edita a Expressão
          reqPromise = ExpressaoService.update(editingId, {
            ...this.getData(),
            _id: editingId,
          });
        }

        reqPromise
          .then(() => {
            this.$refs.modalExpressao.hide();
            this.$emit("update");

            this.editingID = "";
            this.expression = "";
            this.whatsMean = "";
          })
          .catch(() => {
            this.$swal(
              "Erro",
              "Ocorreu um erro ao salvar a expressao, fale com o administrador",
              "error"
            );
          });
      }
    },
    getData() {
      return {
        tipo: this.id,
        valores: [
          {
            campo: "Expression",
            valor: this.expression,
          },
          {
            campo: "What's Mean",
            valor: this.whatsMean,
          },
        ],
      };
    },
    showEdit(data) {
      this.$refs.modalExpressao.show();

      this.editingId = data._id;

      this.expression = this.getField(data, "Expression");
      this.whatsMean = this.getField(data, "What's Mean");
    },
    getField(expressao, campo) {
      return expressao.valores.find((x) => x.campo == campo).valor;
    },
    refresh(){
      this.editingId = "";
      this.expression = "";
      this.whatsMean = "";
      this.$emit("update");
    }
  }
};
</script>

<style>
</style>