<template>
  <b-container class="text-center">

    <h2 class="color-primary-dark mt-4 text-center">Relatório</h2>

    <b-input
      placeholder="Ano"
      v-model="ano"
      class="relatorio-width mx-auto mt-4"
    />

    <b-btn
      @click="gerar"
      class="mt-3 relatorio-width"
      variant="light"
    >Gerar</b-btn>

  </b-container>
</template>

<script>
import * as RelatorioService from "@/services/RelatorioService";

export default {
  data() {
    return {
      ano: new Date().getFullYear()
    };
  },
  methods: {
    gerar() {
      RelatorioService.gerar(this.ano).then(res => {
        const byteCharacters = atob(res.data);

        let byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const url = window.URL.createObjectURL(new Blob([byteArray]));

        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "Report.xlsx");
        a.click();
      });
    }
  }
};
</script>

<style scoped>
.relatorio-width {
  width: 300px;
}
</style>