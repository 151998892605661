<template>
  <div
    :id="id"
    :class="`d-flex justify-content-center align-items-center shadow floatButtonAux text-white ${disabled ? 'disabled' : ''}`"
    @click="$emit('click')"
  >
    <font-awesome-icon
      :icon="['fas', icon]"
      class="icon"
    />

    <b-tooltip
      :target="id"
      :title="label"
      placement="right"
    />

  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    label: String,
    id: String,
    icon: {
      type: String,
      default: "plus"
    }
  }
};
</script>

<style lang="scss" scoped>
.floatButtonAux {
  background: $primary-dark;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  bottom: 2em;
  right: 2em;
  cursor: pointer;
  transition: all 0.2s;
}

.floatButtonAux.disabled {
  background: #cdcdcd;
}

.floatButtonAux:not(.disabled):hover {
  opacity: 0.8;
}

.icon {
  transition: all 0.2s;
}

.floatButtonAux:not(.disabled):hover .icon {
  transform: rotate(180deg);
}
</style>
