import * as AuthService from '@/services/AuthService'

import rootStore from '@/store'

const accountModule = {

  namespaced: true,

  state: {
    user: {
      login: '',
      token: '',
      admin: false
    }
  },

  mutations: {
    login(state, { token, login, admin }){

      if(admin){
        rootStore.dispatch('caixas/fetchAll')
      }

      // Salva o usuário
      return state.user = {
        token,
        login,
        admin
      }

    },
    loggout(state){
      // Remove o Token
      localStorage.removeItem('USER_INFO')
      return state.user = {
        admin: false,
        login: '',
        token: ''
      }
    }
  },
  
  actions: {
    login({ commit }, { username, password }){
      // Requisição de Login
      return AuthService.login(username, password)
      .then(function(res){

        const { token, admin } = res.data

        const user = {
          login: username,
          token,
          admin: admin ? admin : false
        }

        localStorage.setItem("USER_INFO", JSON.stringify(user))
        
        commit('login', user)

        return res
        
      })
    }
  },
}

export default accountModule