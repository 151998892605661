<template>
  <b-modal
    id="modal-alunos-finalizando"
    title="Alunos Finalizando"
    size="lg"
    ok-only
  >

    <table class="table table-striped">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Data Fim</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(aluno, i) in alunos"
          :key="i"
        >
          <td>{{aluno.nome}}</td>
          <td>{{aluno.dtFim | moment('DD/MM/YYYY')}}</td>
        </tr>
      </tbody>
    </table>

  </b-modal>
</template>

<script>
export default {
  props: ["alunos"]
};
</script>

<style>
</style>