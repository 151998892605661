<template>
  <div v-if="dados">
    <dashboard-header :dados="dados" :dadosPf="dadosPf" />

    <alertas
      :alertas="dados.despesasComVencimentoProximo"
      tipo="Despesa"
      class="mt-4 mb-4"
    />
    <alertas
      :alertas="dados.lembretesComVencimentoProximo"
      tipo="Lembrete"
      class="mt-4 mb-4"
    />

    <modal-lista-pagamentos-dia :alunos="listaAlunosModal" />

    <b-row class="m-4" style="display: none;">
      <b-col class="col-8"
        ><b-card> <graph-despesas /> </b-card></b-col
      ><b-col class="col-4">
        <b-card> <resumo-media /></b-card>
      </b-col>
    </b-row>

    <b-row class="mb-4" style="display: none;">
      <resumo-saldo-mensal class="m-2" />
    </b-row>
  </div>
</template>

  <script>
import * as DashboardService from "@/services/DashboardService";

import Alertas from "./dashboardAdmin/Alertas";
import DashboardHeader from "./dashboardAdmin/Header";
import ModalListaPagamentosDia from "./dashboardAdmin/ModalListaPagamentosDia";
import ResumoSaldoMensal from "./dashboardAdmin/ResumoSaldoMensal.vue";
import ResumoMedia from "./dashboardAdmin/ResumoMedia.vue";
import GraphDespesas from "./dashboardAdmin/GraphDespesas";

export default {
  data() {
    return {
      dados: null,
      dadosPf: null,
      listaAlunosModal: [],
    };
  },
  components: {
    Alertas,
    DashboardHeader,
    ModalListaPagamentosDia,
    ResumoSaldoMensal,
    ResumoMedia,
    GraphDespesas,
  },
  mounted() {
    DashboardService.fetchDashboard("pj").then((res) => {
      this.dados = res.data;
    });
    DashboardService.fetchDashboard("pf").then((res) => {
      this.dadosPf = res.data;
    });
  },
  methods: {
    handleDetails(alunos) {
      this.listaAlunosModal = alunos;
      this.$bvModal.show("modal-lista-pagamentos-dia");
    },
  },
};
</script>

  <style>
.row {
  margin: 0;
  padding: 0;
}
</style>
