import api from '.'

export function get(year, month) {
  return api.get(`/agenda/${year}/${month}`)
}

export function add(data) {
  return api.post('/agenda/', data)
}

export function remove(id) {
  return api.delete(`/agenda/${id}`)
}
