<template>
  <b-col>
    <b-card bg-variant="light">
      <h3>Observações</h3>

      <hr />

      <b-row>
        <b-col md="12">
          <b-textarea
            placeholder="Escreva aqui as observações do aluno..."
            v-model="dados.observacoes"
            rows="5"
          >
          </b-textarea>
        </b-col>
      </b-row>

    </b-card>
  </b-col>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["dados"],
};
</script>