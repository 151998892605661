import axios from "axios";

import router from '@/router'
import swal from 'sweetalert2'

var api = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
});

api.interceptors.request.use(req => {
  if(!req.url.includes('/auth')){
    req.headers = { ...getAuthorization() }
  }

  return req
})

api.interceptors.response.use(res => res, err => {

  if(!err.response.config.url.includes('/auth')){
    if(err.response.status == 401) {
      swal.fire({
        title: "Erro",
        text: "Sessão expirada, faça login novamente",
        type: "error"
      }).then(() => {
        router.push('/login')
      })
    }
  }

  return Promise.reject(err);
})

export function getAuthorization() {

  let user = JSON.parse(localStorage.getItem('USER_INFO'))

  return {
    Authorization: `Bearer ${user.token}`
  }
}

export default api;
