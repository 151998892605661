import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import './registerServiceWorker'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./assets/fonts/fonts.css";
import "./assets/scss/custom.scss";
import "vue2-datepicker/index.css"

library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

import VueMask from "v-mask";
Vue.use(VueMask);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import 'fullcalendar/dist/fullcalendar.css'
import FullCalendar from 'vue-full-calendar'
Vue.use(FullCalendar);

import VueCurrencyFilter from "vue-currency-filter";
Vue.use(VueCurrencyFilter, {
  symbol: "",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true
});

import moment from "moment";
import VueMoment from "vue-moment";
import "moment/locale/pt-br";

Vue.use(VueMoment, {
  moment
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");