<template>
  <div>
    <span class="button-view">
      <span v-if="toogle">{{senha}}</span>

      <b-button
        v-else
        @mousedown="mostrar"
        @mouseup="esconder"
        variant="light"
      >
        <span v-if="toogle">{{senha}}</span>
        <font-awesome-icon
          v-if="!toogle"
          :icon="['fas', 'eye-slash']"
          class="text-danger"
        />
      </b-button>
    </span>
  </div>
</template>

<script>
export default {
  props: ["senha"],
  data: () => ({
    toogle: false,
  }),
  methods: {
    mostrar() {
      this.toogle = true;
    },
    esconder() {
      this.toogle = false;
    },
  },
};
</script>
<style>
.button-view button {
  background-color: transparent !important;
  border: none !important;
  border-color: transparent !important;
}
</style>
